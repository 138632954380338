/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { EuiAccordionButton } from './accordion_button';
import { EuiAccordionArrow } from './accordion_arrow';
export var EuiAccordionTrigger = ({
  arrowDisplay: _arrowDisplay,
  arrowProps,
  buttonElement = 'button',
  buttonProps,
  buttonClassName,
  buttonContent,
  buttonContentClassName,
  buttonId,
  ariaControlsId,
  isDisabled,
  isOpen,
  onToggle,
  extraAction
}) => {
  // Force visibility of arrow button icon if button element is not interactive
  const buttonIsInteractive = buttonElement === 'button';
  const arrowDisplay = _arrowDisplay === 'none' && !buttonIsInteractive ? 'left' : _arrowDisplay;
  const arrow = <EuiAccordionArrow arrowDisplay={arrowDisplay} arrowProps={arrowProps} isOpen={isOpen} onClick={onToggle} isDisabled={isDisabled} aria-controls={ariaControlsId} aria-expanded={isOpen} aria-labelledby={buttonId} tabIndex={buttonIsInteractive ? -1 : 0} />;
  const button = <EuiAccordionButton buttonElement={buttonElement} buttonProps={buttonProps} buttonClassName={buttonClassName} buttonContentClassName={buttonContentClassName} arrowDisplay={arrowDisplay} isDisabled={isDisabled} id={buttonId} aria-controls={ariaControlsId} aria-expanded={buttonIsInteractive ? isOpen : undefined} // `aria-expanded` is only a valid attribute on interactive controls - axe-core throws a violation otherwise
  onClick={isDisabled ? undefined : onToggle}>
      {buttonContent}
    </EuiAccordionButton>;
  const optionalAction = extraAction && <div className="euiAccordion__optionalAction" css={{
    flexShrink: 0,
    label: 'euiAccordion'
  }}>
      {extraAction}
    </div>;
  return <div className="euiAccordion__triggerWrapper" css={{
    display: 'flex',
    alignItems: 'center'
  }}>
      {arrowDisplay === 'left' && arrow}
      {button}
      {optionalAction}
      {arrowDisplay === 'right' && arrow}
    </div>;
};
EuiAccordionTrigger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  ariaControlsId: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired
};
try {
  EuiAccordionTrigger.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/accordion/accordion_trigger/accordion_trigger.tsx',
    description: '',
    displayName: 'EuiAccordionTrigger',
    methods: [],
    props: {
      isDisabled: {
        defaultValue: null,
        description: 'Disable the open/close interaction and visually subdues the trigger',
        name: 'isDisabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      buttonClassName: {
        defaultValue: null,
        description: 'Class that will apply to the trigger for the accordion.',
        name: 'buttonClassName',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      buttonProps: {
        defaultValue: null,
        description: 'Apply more props to the triggering button.\n' + '\n' + 'Includes optional `paddingSize` prop which allows sizes of `s`, `m`, or `l`.\n' + 'Note: Padding will not be present on the side closest to the accordion arrow.',
        name: 'buttonProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLElement> & { paddingSize?: "s" | "m" | "l"; }'
        }
      },
      buttonContentClassName: {
        defaultValue: null,
        description: 'Class that will apply to the trigger content for the accordion.',
        name: 'buttonContentClassName',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      buttonContent: {
        defaultValue: null,
        description: 'The content of the clickable trigger',
        name: 'buttonContent',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      buttonElement: {
        defaultValue: {
          value: 'button'
        },
        description: 'Applied to the main button receiving the `onToggle` event.\n' + 'Anything other than the default `button` does not support removing the arrow display (for accessibility of focus).',
        name: 'buttonElement',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "div" | "legend"',
          value: [{
            value: '"button"'
          }, {
            value: '"div"'
          }, {
            value: '"legend"'
          }]
        }
      },
      arrowProps: {
        defaultValue: null,
        description: 'Extra props to pass to the EuiButtonIcon containing the arrow.',
        name: 'arrowProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiButtonIconProps, "aria-labelledby" | "onClick" | "iconType">>'
        }
      },
      extraAction: {
        defaultValue: null,
        description: 'Will appear right aligned against the button. Useful for separate actions like deletions.',
        name: 'extraAction',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      arrowDisplay: {
        defaultValue: null,
        description: "Placement of the arrow indicator, or 'none' to hide it.",
        name: 'arrowDisplay',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "left" | "right"',
          value: [{
            value: '"none"'
          }, {
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      isOpen: {
        defaultValue: null,
        description: '',
        name: 'isOpen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion_trigger/accordion_trigger.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      ariaControlsId: {
        defaultValue: null,
        description: '',
        name: 'ariaControlsId',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion_trigger/accordion_trigger.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      buttonId: {
        defaultValue: null,
        description: '',
        name: 'buttonId',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion_trigger/accordion_trigger.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      onToggle: {
        defaultValue: null,
        description: '',
        name: 'onToggle',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion_trigger/accordion_trigger.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'MouseEventHandler<Element>'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}