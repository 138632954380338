/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { euiTimelineItemEventStyles } from './timeline_item_event.styles';
export var EuiTimelineItemEvent = ({
  children,
  verticalAlign = 'center'
}) => {
  const styles = euiTimelineItemEventStyles();
  const cssStyles = [styles.euiTimelineItemEvent, styles[verticalAlign]];
  return <div css={cssStyles}>{children}</div>;
};
EuiTimelineItemEvent.propTypes = {
  /**
     * Accepts any node. But preferably `EuiPanel`
     */
  children: PropTypes.node.isRequired,
  verticalAlign: PropTypes.any
};
try {
  EuiTimelineItemEvent.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/timeline/timeline_item_event.tsx',
    description: '',
    displayName: 'EuiTimelineItemEvent',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: 'Accepts any node. But preferably `EuiPanel`',
        name: 'children',
        parent: {
          fileName: 'eui/src/components/timeline/timeline_item_event.tsx',
          name: 'EuiTimelineItemEventProps'
        },
        declarations: [{
          fileName: 'eui/src/components/timeline/timeline_item_event.tsx',
          name: 'EuiTimelineItemEventProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      verticalAlign: {
        defaultValue: {
          value: 'center'
        },
        description: '',
        name: 'verticalAlign',
        parent: {
          fileName: 'eui/src/components/timeline/timeline_item_event.tsx',
          name: 'EuiTimelineItemEventProps'
        },
        declarations: [{
          fileName: 'eui/src/components/timeline/timeline_item_event.tsx',
          name: 'EuiTimelineItemEventProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"top" | "center"',
          value: [{
            value: '"top"'
          }, {
            value: '"center"'
          }]
        }
      }
    },
    extendedInterfaces: ['EuiTimelineItemEventProps']
  };
} catch (e) {}