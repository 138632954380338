const _excluded = ["levels", "max", "min", "showTicks", "showRange", "trackWidth"];
function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function (n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
import "core-js/modules/es.array.map.js";
function _objectWithoutProperties(e, t) {
  if (null == e) return {};
  var o,
    r,
    i = _objectWithoutPropertiesLoose(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
  }
  return i;
}
function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (e.indexOf(n) >= 0) continue;
    t[n] = r[n];
  }
  return t;
}
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo, useEffect } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../../services';
import { logicalStyles } from '../../../global_styling';
import { isNamedLevelColor } from './range_levels_colors';
import { euiRangeLevelsStyles, euiRangeLevelStyles } from './range_levels.styles';
import { calculateThumbPosition, EUI_THUMB_SIZE } from './utils';
export var EuiRangeLevels = function EuiRangeLevels(_ref) {
  var _ref$levels = _ref.levels,
    levels = _ref$levels === void 0 ? [] : _ref$levels,
    max = _ref.max,
    min = _ref.min,
    showTicks = _ref.showTicks,
    showRange = _ref.showRange,
    trackWidth = _ref.trackWidth,
    rest = _objectWithoutProperties(_ref, _excluded);
  var euiTheme = useEuiTheme();
  var styles = euiRangeLevelsStyles(euiTheme);
  var cssStyles = [styles.euiRangeLevels, showTicks && styles.hasTicks, showRange && styles.hasRange];
  return ___EmotionJSX("div", _extends({
    className: "euiRangeLevels",
    css: cssStyles
  }, rest), levels.map(function (level, index) {
    return ___EmotionJSX(EuiRangeLevelElement, {
      key: index,
      level: level,
      min: min,
      max: max,
      trackWidth: trackWidth
    });
  }));
};

// Internal subcomponent
EuiRangeLevels.propTypes = {
  trackWidth: PropTypes.number.isRequired,
  style: PropTypes.any
};
var EuiRangeLevelElement = ({
  level,
  min,
  max,
  trackWidth
}) => {
  const {
    color,
    className,
    min: levelMin,
    max: levelMax,
    ...levelRest
  } = level;
  const isNamedColor = useMemo(() => isNamedLevelColor(color), [color]);
  useEffect(() => {
    validateLevelIsInRange({
      min: levelMin,
      max: levelMax
    }, {
      min,
      max
    });
  }, [levelMin, levelMax, min, max]);
  const styles = useMemo(() => {
    let left = 0;
    let right = 0;
    let leftOffset = 0;
    let rightOffset = 0;
    if (trackWidth > 0) {
      left = levelMin === min ? 0 : calculateThumbPosition(levelMin, min, max, trackWidth);
      leftOffset = calculateOffset(left, levelMin, min);
      right = levelMax === max ? 100 : calculateThumbPosition(levelMax, min, max, trackWidth);
      rightOffset = calculateOffset(right, levelMax, max);
    }
    return logicalStyles({
      left: `calc(${left}% + ${leftOffset}px)`,
      right: `calc(${100 - right}% - ${rightOffset}px)`,
      backgroundColor: !isNamedColor ? color : undefined
    });
  }, [levelMin, levelMax, min, max, trackWidth, isNamedColor, color]);
  const levelClasses = classNames('euiRangeLevel', className);
  const euiTheme = useEuiTheme();
  const levelStyles = euiRangeLevelStyles(euiTheme);
  const cssLevelStyles = [levelStyles.euiRangeLevel, isNamedColor ? levelStyles[color] : levelStyles.customColor];
  return <span style={styles} className={levelClasses} css={cssLevelStyles} {...levelRest} />;
};
EuiRangeLevelElement.propTypes = {
  level: PropTypes.shape({
    /**
       * Accepts one of `["primary", "success", "warning", "danger"]` or a valid CSS color value.
       */
    color: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]).isRequired,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired,
  min: PropTypes.any.isRequired,
  max: PropTypes.any.isRequired,
  trackWidth: PropTypes.number.isRequired
};
const validateLevelIsInRange = (level, {
  min,
  max
}) => {
  if (level.min < min) {
    throw new Error(`The level min of ${level.min} is lower than the min value of ${min}.`);
  }
  if (level.max > max) {
    throw new Error(`The level max of ${level.max} is higher than the max value of ${max}.`);
  }
};
const calculateOffset = (position, value, bound) => {
  const threshold = 30;
  let offset = value === bound ? 0 : EUI_THUMB_SIZE / 2;
  if (offset !== 0) {
    // Estimating offset by eye. Trying to account for range scaling at both ends.
    offset = position <= threshold ? offset + 1 / position * threshold : offset;
    offset = position >= 100 - threshold ? offset - 1 / (100 - position) * threshold : offset;
  }
  return offset;
};
try {
  EuiRangeLevels.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/range_levels.tsx',
    description: '',
    displayName: 'EuiRangeLevels',
    methods: [],
    props: {
      trackWidth: {
        defaultValue: null,
        description: '',
        name: 'trackWidth',
        parent: {
          fileName: 'eui/src/components/form/range/range_levels.tsx',
          name: 'EuiRangeLevelsProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_levels.tsx',
          name: 'EuiRangeLevelsProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      style: {
        defaultValue: null,
        description: '',
        name: 'style',
        parent: {
          fileName: 'eui/src/components/form/range/range_levels.tsx',
          name: 'EuiRangeLevelsProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_levels.tsx',
          name: 'EuiRangeLevelsProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      levels: {
        defaultValue: {
          value: '[]'
        },
        description: 'Create colored indicators for certain intervals.\n' + 'An array of #EuiRangeLevel objects',
        name: 'levels',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeLevel[]'
        }
      },
      showRange: {
        defaultValue: null,
        description: 'Shows a thick line from min to value',
        name: 'showRange',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: null,
        description: 'Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)',
        name: 'showTicks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['EuiRangeLevelsProps', '_SharedRangeValuesProps', '_SharedRangeDataStructures', '_SharedRangeVisualConfiguration']
  };
} catch (e) {}