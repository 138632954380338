/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { cloneElement, useRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles, useGeneratedHtmlId, useIsWithinMinBreakpoint, useCombinedRefs } from '../../services';
import { EuiFlyout } from '../flyout';
import { euiCollapsibleNavStyles } from './collapsible_nav.styles';

// Extend all the flyout props except `onClose` because we handle this internally

export var EuiCollapsibleNav = ({
  id,
  children,
  className,
  isDocked = false,
  isOpen = false,
  button,
  showButtonIfDocked = false,
  dockedBreakpoint = 'l',
  // Setting different EuiFlyout defaults
  as = 'nav',
  size = 320,
  side = 'left',
  ownFocus = true,
  outsideClickCloses = true,
  closeButtonPosition = 'outside',
  paddingSize = 'none',
  focusTrapProps: _focusTrapProps = {},
  ...rest
}) => {
  const flyoutID = useGeneratedHtmlId({
    conditionalId: id,
    suffix: 'euiCollapsibleNav'
  });
  const buttonRef = useRef();
  const combinedButtonRef = useCombinedRefs([button?.props.ref, buttonRef]);
  const focusTrapProps = {
    ..._focusTrapProps,
    shards: [buttonRef, ...(_focusTrapProps.shards || [])]
  };
  const windowIsLargeEnoughToPush = useIsWithinMinBreakpoint(dockedBreakpoint);
  const navIsDocked = isDocked && windowIsLargeEnoughToPush;
  const flyoutType = navIsDocked ? 'push' : 'overlay';
  const classes = classNames('euiCollapsibleNav', className);
  const styles = useEuiMemoizedStyles(euiCollapsibleNavStyles);
  const cssStyles = [styles.euiCollapsibleNav, styles[flyoutType]];

  // Show a trigger button if one was passed but
  // not if navIsDocked and showButtonIfDocked is false
  const trigger = navIsDocked && !showButtonIfDocked ? undefined : button && cloneElement(button, {
    'aria-controls': flyoutID,
    'aria-expanded': isOpen,
    'aria-pressed': isOpen,
    // When EuiOutsideClickDetector is enabled, we don't want both the toggle button and document touches/clicks to happen, they'll cancel eachother out
    onTouchEnd: e => {
      e.nativeEvent.stopImmediatePropagation();
    },
    onMouseUpCapture: e => {
      e.nativeEvent.stopImmediatePropagation();
    },
    ref: combinedButtonRef
  });
  const flyout = <EuiFlyout id={flyoutID} css={cssStyles} className={classes}
  // Flyout props we set different defaults for
  as={as} size={size} side={side} ownFocus={ownFocus} outsideClickCloses={outsideClickCloses} closeButtonPosition={closeButtonPosition} paddingSize={paddingSize} focusTrapProps={focusTrapProps} {...rest}
  // Props dependent on internal docked status
  type={flyoutType} hideCloseButton={navIsDocked} pushMinBreakpoint={dockedBreakpoint}>
      {children}
    </EuiFlyout>;
  return <>
      {trigger}
      {(isOpen || navIsDocked) && flyout}
    </>;
};
EuiCollapsibleNav.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Sets the HTML element for `EuiFlyout`
     */
  as: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  /**
     * Defines the width of the panel.
     * Pass a predefined size of `s | m | l`, or pass any number/string compatible with the CSS `width` attribute
     * @default m
     */
  size: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]),
  /**
     * Sets the max-width of the panel,
     * set to `true` to use the default size,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     * @default false
     */
  maxWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),
  /**
     * Customize the padding around the content of the flyout header, body and footer
     * @default l
     */
  paddingSize: PropTypes.any,
  /**
     * Adds an EuiOverlayMask and wraps in an EuiPortal
     * @default true
     */
  ownFocus: PropTypes.bool,
  /**
     * Hides the default close button. You must provide another close button somewhere within the flyout.
     * @default false
     */
  hideCloseButton: PropTypes.bool,
  /**
     * Extends EuiButtonIconProps onto the close button
     */
  closeButtonProps: PropTypes.any,
  /**
     * Position of close button.
     * `inside`: Floating to just inside the flyout, always top right;
     * `outside`: Floating just outside the flyout near the top (side dependent on `side`). Helpful when the close button may cover other interactable content.
     * @default inside
     */
  closeButtonPosition: PropTypes.oneOf(["inside", "outside"]),
  /**
     * Adjustments to the EuiOverlayMask that is added when `ownFocus = true`
     */
  maskProps: PropTypes.shape({
    /**
       * ReactNode to render as this component's content
       */
    children: PropTypes.node,
    /**
       * Should the mask visually sit above or below the EuiHeader (controlled by z-index)
       */
    headerZindexLocation: PropTypes.oneOf(["above", "below"]),
    /**
       * React ref to be passed to the wrapping container
       */
    maskRef: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired])
  }),
  /**
     * Forces this interaction on the mask overlay or body content.
     * Defaults depend on `ownFocus` and `type` values
     */
  outsideClickCloses: PropTypes.bool,
  /**
     * Which side of the window to attach to.
     * The `left` option should only be used for navigation.
     * @default right
     */
  side: PropTypes.any,
  /**
     * Named breakpoint (`xs` through `xl`) for customizing the minimum window width to enable docking
     */
  pushMinBreakpoint: PropTypes.any,
  /**
     * Enables a slide in animation on push flyouts
     * @default false
     */
  pushAnimation: PropTypes.bool,
  style: PropTypes.any,
  /**
     * Object of props passed to EuiFocusTrap.
     * `shards` specifies an array of elements that will be considered part of the flyout, preventing the flyout from being closed when clicked.
     * `closeOnMouseup` will delay the close callback, allowing time for external toggle buttons to handle close behavior.
     */
  focusTrapProps: PropTypes.any,
  /**
     * By default, EuiFlyout will consider any fixed `EuiHeader`s that sit alongside or above the EuiFlyout
     * as part of the flyout's focus trap. This prevents focus fighting with interactive elements
     * within fixed headers.
     *
     * Set this to `false` if you need to disable this behavior for a specific reason.
     */
  includeFixedHeadersInFocusTrap: PropTypes.bool,
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node,
  /**
     * Shows the navigation flyout
     */
  isOpen: PropTypes.bool,
  /**
     * Keeps navigation flyout visible and push `<body>` content via padding
     */
  isDocked: PropTypes.bool,
  dockedBreakpoint: PropTypes.any,
  /**
     * Button for controlling visible state of the nav
     */
  button: PropTypes.element,
  /**
     * Keeps the display of toggle button when in docked state
     */
  showButtonIfDocked: PropTypes.bool
};
try {
  EuiCollapsibleNav.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/collapsible_nav/collapsible_nav.tsx',
    description: '',
    displayName: 'EuiCollapsibleNav',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      style: {
        defaultValue: null,
        description: '',
        name: 'style',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/collapsible_nav/collapsible_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: {
          value: '320'
        },
        description: 'Defines the width of the panel.\n' + 'Pass a predefined size of `s | m | l`, or pass any number/string compatible with the CSS `width` attribute',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'Width<string | number>'
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'none'
        },
        description: 'Customize the padding around the content of the flyout header, body and footer',
        name: 'paddingSize',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "s" | "m" | "l"',
          value: [{
            value: '"none"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }]
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'string | ((instance: HTMLDivElement) => void) | RefObject<HTMLDivElement> | ((instance: HTMLElement) => void) | RefObject<HTMLElement>'
        }
      },
      maxWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Sets the max-width of the panel,\n' + 'set to `true` to use the default size,\n' + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'maxWidth',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'string | number | boolean'
        }
      },
      closeButtonProps: {
        defaultValue: null,
        description: 'Extends EuiButtonIconProps onto the close button',
        name: 'closeButtonProps',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'Partial<EuiButtonIconPropsForButton>'
        }
      },
      onClose: {
        defaultValue: null,
        description: '',
        name: 'onClose',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: true,
        type: {
          name: '(event: MouseEvent | TouchEvent | KeyboardEvent) => void'
        }
      },
      ownFocus: {
        defaultValue: {
          value: 'true'
        },
        description: 'Adds an EuiOverlayMask and wraps in an EuiPortal',
        name: 'ownFocus',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hideCloseButton: {
        defaultValue: {
          value: 'false'
        },
        description: 'Hides the default close button. You must provide another close button somewhere within the flyout.',
        name: 'hideCloseButton',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      closeButtonPosition: {
        defaultValue: {
          value: 'outside'
        },
        description: 'Position of close button.\n' + '`inside`: Floating to just inside the flyout, always top right;\n' + '`outside`: Floating just outside the flyout near the top (side dependent on `side`). Helpful when the close button may cover other interactable content.',
        name: 'closeButtonPosition',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inside" | "outside"',
          value: [{
            value: '"inside"'
          }, {
            value: '"outside"'
          }]
        }
      },
      maskProps: {
        defaultValue: null,
        description: 'Adjustments to the EuiOverlayMask that is added when `ownFocus = true`',
        name: 'maskProps',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'EuiOverlayMaskProps'
        }
      },
      outsideClickCloses: {
        defaultValue: {
          value: 'true'
        },
        description: 'Forces this interaction on the mask overlay or body content.\n' + 'Defaults depend on `ownFocus` and `type` values',
        name: 'outsideClickCloses',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      side: {
        defaultValue: {
          value: 'left'
        },
        description: 'Which side of the window to attach to.\n' + 'The `left` option should only be used for navigation.',
        name: 'side',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      pushMinBreakpoint: {
        defaultValue: {
          value: 'l'
        },
        description: 'Named breakpoint (`xs` through `xl`) for customizing the minimum window width to enable the `push` type',
        name: 'pushMinBreakpoint',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      pushAnimation: {
        defaultValue: {
          value: 'false'
        },
        description: 'Enables a slide in animation on push flyouts',
        name: 'pushAnimation',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      focusTrapProps: {
        defaultValue: null,
        description: 'Object of props passed to EuiFocusTrap.\n' + '`shards` specifies an array of elements that will be considered part of the flyout, preventing the flyout from being closed when clicked.\n' + '`closeOnMouseup` will delay the close callback, allowing time for external toggle buttons to handle close behavior.',
        name: 'focusTrapProps',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'Pick<EuiFocusTrapProps, "shards" | "closeOnMouseup">'
        }
      },
      includeFixedHeadersInFocusTrap: {
        defaultValue: null,
        description: 'By default, EuiFlyout will consider any fixed `EuiHeader`s that sit alongside or above the EuiFlyout\n' + "as part of the flyout's focus trap. This prevents focus fighting with interactive elements\n" + 'within fixed headers.\n' + '\n' + 'Set this to `false` if you need to disable this behavior for a specific reason.',
        name: 'includeFixedHeadersInFocusTrap',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      as: {
        defaultValue: {
          value: 'nav'
        },
        description: 'Sets the HTML element for `EuiFlyout`',
        name: 'as',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"div" | "nav"',
          value: [{
            value: '"div"'
          }, {
            value: '"nav"'
          }]
        }
      },
      isOpen: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows the navigation flyout',
        name: 'isOpen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/collapsible_nav/collapsible_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isDocked: {
        defaultValue: {
          value: 'false'
        },
        description: 'Keeps navigation flyout visible and push `<body>` content via padding',
        name: 'isDocked',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/collapsible_nav/collapsible_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      dockedBreakpoint: {
        defaultValue: {
          value: 'l'
        },
        description: 'Named breakpoint (`xs` through `xl`) for customizing the minimum window width to enable docking',
        name: 'dockedBreakpoint',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/collapsible_nav/collapsible_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      button: {
        defaultValue: null,
        description: 'Button for controlling visible state of the nav',
        name: 'button',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/collapsible_nav/collapsible_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactElement'
        }
      },
      showButtonIfDocked: {
        defaultValue: {
          value: 'false'
        },
        description: 'Keeps the display of toggle button when in docked state',
        name: 'showButtonIfDocked',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/collapsible_nav/collapsible_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', '_EuiFlyoutProps', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'Attributes']
  };
} catch (e) {}