/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useCallback, useMemo, forwardRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiCallOut } from '../call_out';
import { EuiI18n } from '../i18n';
import { EuiSpacer } from '../spacer';
import { FormContext } from './eui_form_context';
export var EuiForm = forwardRef(({
  children,
  className,
  isInvalid,
  error,
  component = 'div',
  invalidCallout = 'above',
  fullWidth,
  ...rest
}, ref) => {
  const formContext = useMemo(() => ({
    defaultFullWidth: fullWidth ?? false
  }), [fullWidth]);
  const handleFocus = useCallback(node => {
    node?.focus();
  }, []);
  const classes = classNames('euiForm', className);
  let optionalErrors = null;
  if (error) {
    const errorTexts = Array.isArray(error) ? error : [error];
    optionalErrors = <ul>
          {errorTexts.map((error, index) => <li className="euiForm__error" key={index}>
              {error}
            </li>)}
        </ul>;
  }
  let optionalErrorAlert;
  if (isInvalid && invalidCallout === 'above') {
    optionalErrorAlert = <EuiI18n token="euiForm.addressFormErrors" default="Please address the highlighted errors.">
          {addressFormErrors => <>
              <EuiCallOut tabIndex={-1} ref={handleFocus} className="euiForm__errors" title={addressFormErrors} color="danger" role="alert" aria-live="assertive">
                {optionalErrors}
              </EuiCallOut>
              <EuiSpacer size="m" />
            </>}
        </EuiI18n>;
  }
  const Element = component;
  return <Element
  // @ts-expect-error Element is a <div> or <form>, but TypeScript wants to support both
  ref={ref} className={classes} {...rest}>
        <FormContext.Provider value={formContext}>
          {optionalErrorAlert}
          {children}
        </FormContext.Provider>
      </Element>;
});
EuiForm.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Which HTML element to render `div` or `form`
       */
  component: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.oneOf(["form"]).isRequired, PropTypes.oneOf(["div"])]), PropTypes.oneOf(["form", "div"])]),
  isInvalid: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.arrayOf(PropTypes.node.isRequired).isRequired]),
  /**
       * Where to display the callout with the list of errors
       */
  invalidCallout: PropTypes.oneOf(["above", "none"]),
  /**
       * When set to `true`, all the rows/controls in this form will
       * default to taking up 100% of the width of their continer. You
       * can specify `fullWidth={false}` on individual rows/controls to
       * disable this behavior for specific components.
       * @default false
       */
  fullWidth: PropTypes.bool
};
EuiForm.displayName = 'EuiForm';
try {
  EuiForm.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/form.tsx',
    description: '',
    displayName: 'EuiForm',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      component: {
        defaultValue: {
          value: 'div'
        },
        description: 'Which HTML element to render `div` or `form`',
        name: 'component',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"form" | "div"',
          value: [{
            value: '"form"'
          }, {
            value: '"div"'
          }]
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      error: {
        defaultValue: null,
        description: '',
        name: 'error',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode | ReactNode[]'
        }
      },
      invalidCallout: {
        defaultValue: {
          value: 'above'
        },
        description: 'Where to display the callout with the list of errors',
        name: 'invalidCallout',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "above"',
          value: [{
            value: '"none"'
          }, {
            value: '"above"'
          }]
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'When set to `true`, all the rows/controls in this form will\n' + 'default to taking up 100% of the width of their continer. You\n' + 'can specify `fullWidth={false}` on individual rows/controls to\n' + 'disable this behavior for specific components.',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<HTMLElement>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'FormHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'Attributes']
  };
} catch (e) {}