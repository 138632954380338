/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import { useEuiTheme } from '../../../services';
import { logicalStyles } from '../../../global_styling';
import { euiRangeTooltipStyles, euiRangeTooltipValueStyles } from './range_tooltip.styles';
export var EuiRangeTooltip = ({
  value,
  valueAppend,
  valuePrepend,
  max,
  min,
  name,
  showTicks
}) => {
  // Calculate the left position based on value
  const valuePosition = useMemo(() => {
    let val = 0;
    if (typeof value === 'number') {
      val = value;
    } else if (typeof value === 'string') {
      val = parseFloat(value);
    }
    const decimal = (val - min) / (max - min);
    // Must be between 0-100%
    const valuePosition = decimal <= 1 ? decimal : 1;
    return valuePosition >= 0 ? valuePosition : 0;
  }, [value, min, max]);

  // Change left/right position based on value (half way point)
  const valuePositionSide = useMemo(() => valuePosition > 0.5 ? 'left' : 'right', [valuePosition]);
  const valuePositionStyle = useMemo(() => {
    if (valuePositionSide === 'left') {
      return logicalStyles({
        right: `${(1 - valuePosition) * 100}%`
      });
    } else if (valuePositionSide === 'right') {
      return logicalStyles({
        left: `${valuePosition * 100}%`
      });
    }
  }, [valuePosition, valuePositionSide]);
  const euiTheme = useEuiTheme();
  const styles = euiRangeTooltipStyles(euiTheme);
  const cssStyles = [styles.euiRangeTooltip];
  const valueStyles = euiRangeTooltipValueStyles(euiTheme);
  const cssValueStyles = [valueStyles.euiRangeTooltip__value, valueStyles[valuePositionSide], showTicks && valueStyles.hasTicks];
  return <div className="euiRangeTooltip" css={cssStyles}>
      <output className="euiRangeTooltip__value" css={cssValueStyles} htmlFor={name} style={valuePositionStyle}>
        {valuePrepend}
        {value}
        {valueAppend}
      </output>
    </div>;
};
EuiRangeTooltip.propTypes = {
  name: PropTypes.string
};
try {
  EuiRangeTooltip.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/range_tooltip.tsx',
    description: '',
    displayName: 'EuiRangeTooltip',
    methods: [],
    props: {
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'eui/src/components/form/range/range_tooltip.tsx',
          name: 'EuiRangeTooltipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_tooltip.tsx',
          name: 'EuiRangeTooltipProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SingleRangeValue'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SingleRangeValue'
        }],
        required: true,
        type: {
          name: 'string | number'
        }
      },
      showTicks: {
        defaultValue: null,
        description: 'Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)',
        name: 'showTicks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      valueAppend: {
        defaultValue: null,
        description: 'Appends to the tooltip',
        name: 'valueAppend',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      valuePrepend: {
        defaultValue: null,
        description: 'Prepends to the tooltip',
        name: 'valuePrepend',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: ['EuiRangeTooltipProps', '_SharedRangeValuesProps', '_SingleRangeValue', '_SharedRangeVisualConfiguration', 'EuiRangeProps']
  };
} catch (e) {}