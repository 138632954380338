/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useContext } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiPaddingCSS } from '../../global_styling';
import { useEuiTheme } from '../../services';
import { euiPopoverTitleStyles } from './popover_title.styles';
import { EuiPopoverPanelContext } from './popover_panel/_popover_panel';
export var EuiPopoverTitle = ({
  children,
  className,
  paddingSize,
  ...rest
}) => {
  const {
    paddingSize: panelPadding
  } = useContext(EuiPopoverPanelContext);
  const euiTheme = useEuiTheme();
  const styles = euiPopoverTitleStyles(euiTheme);
  const paddingStyles = useEuiPaddingCSS();
  const cssStyles = [styles.euiPopoverTitle, styles.panelPaddingSizes[panelPadding],
  // If a paddingSize is not directly provided, inherit from the EuiPopoverPanel
  paddingStyles[paddingSize || panelPadding]];
  const classes = classNames('euiPopoverTitle', className);
  return <div css={cssStyles} className={classes} {...rest}>
      {children}
    </div>;
};
EuiPopoverTitle.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
         * Customize the all around padding of the popover title.
         * Leave `undefined` to inherit from the `panelPaddingSize` of the containing EuiPopover
         */
  paddingSize: PropTypes.any
};
try {
  EuiPopoverTitle.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/popover/popover_title.tsx',
    description: '',
    displayName: 'EuiPopoverTitle',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      paddingSize: {
        defaultValue: null,
        description: 'Customize the all around padding of the popover title.\n' + 'Leave `undefined` to inherit from the `panelPaddingSize` of the containing EuiPopover',
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/popover/popover_title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "s" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"none"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}