/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../../services';
import { logicalStyles } from '../../../global_styling';
import { euiRangeHighlightStyles, euiRangeHighlightProgressStyles, euiRangeHighlightLevelsWrapperStyles, euiRangeHighlightLevelsStyles } from './range_highlight.styles';
import { EuiRangeLevels } from './range_levels';
export var EuiRangeHighlight = ({
  className,
  showTicks,
  trackWidth,
  lowerValue,
  upperValue,
  max,
  min,
  background,
  onClick,
  levels
}) => {
  // Calculate the width of the range based on value
  const leftPosition = useMemo(() => {
    return (lowerValue - min) / (max - min);
  }, [lowerValue, min, max]);
  const rangeWidth = useMemo(() => {
    return (upperValue - lowerValue) / (max - min);
  }, [upperValue, lowerValue, min, max]);
  const classes = classNames('euiRangeHighlight', className);
  const euiTheme = useEuiTheme();
  const styles = euiRangeHighlightStyles(euiTheme);
  const cssStyles = [styles.euiRangeHighlight, showTicks && styles.hasTicks];
  const progressStyles = euiRangeHighlightProgressStyles(euiTheme);
  const cssProgressStyles = [progressStyles.euiRangeHighlight__progress];
  const progressStyle = useMemo(() => {
    return logicalStyles({
      background,
      marginLeft: `${leftPosition * 100}%`,
      width: `${rangeWidth * 100}%`
    });
  }, [background, leftPosition, rangeWidth]);
  const levelsWrapperStyles = euiRangeHighlightLevelsWrapperStyles(euiTheme);
  const cssLevelsWrapperStyles = [levelsWrapperStyles.euiRangeHighlight__levelsWrapper];
  const levelsWrapperStyle = useMemo(() => {
    return logicalStyles({
      marginLeft: `${leftPosition * 100}%`,
      width: `${rangeWidth * 100}%`
    });
  }, [leftPosition, rangeWidth]);
  const levelsStyles = euiRangeHighlightLevelsStyles(euiTheme);
  const cssLevelsStyles = [levelsStyles.euiRangeHighlight__levels];
  const levelsStyle = useMemo(() => {
    return logicalStyles({
      left: `-${trackWidth * leftPosition}px`,
      width: `${trackWidth}px`
    });
  }, [trackWidth, leftPosition]);
  return <div className={classes} css={cssStyles} onClick={onClick}>
      {(levels && levels.length === 0 || !levels) && <div data-test-subj="euiRangeHighlightProgress" className="euiRangeHighlight__progress" css={cssProgressStyles} style={progressStyle} />}

      {levels && !!levels.length && <div css={cssLevelsWrapperStyles} style={levelsWrapperStyle}>
          <EuiRangeLevels css={cssLevelsStyles} style={levelsStyle} levels={levels} max={max} min={min} showTicks={showTicks} trackWidth={trackWidth} />
        </div>}
    </div>;
};
EuiRangeHighlight.propTypes = {
  className: PropTypes.string,
  background: PropTypes.string,
  trackWidth: PropTypes.number.isRequired,
  lowerValue: PropTypes.number.isRequired,
  upperValue: PropTypes.number.isRequired,
  onClick: PropTypes.func
};
try {
  EuiRangeHighlight.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/range_highlight.tsx',
    description: '',
    displayName: 'EuiRangeHighlight',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/form/range/range_highlight.tsx',
          name: 'EuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_highlight.tsx',
          name: 'EuiRangeHighlightProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      background: {
        defaultValue: null,
        description: '',
        name: 'background',
        parent: {
          fileName: 'eui/src/components/form/range/range_highlight.tsx',
          name: 'EuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_highlight.tsx',
          name: 'EuiRangeHighlightProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      trackWidth: {
        defaultValue: null,
        description: '',
        name: 'trackWidth',
        parent: {
          fileName: 'eui/src/components/form/range/range_highlight.tsx',
          name: 'EuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_highlight.tsx',
          name: 'EuiRangeHighlightProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      lowerValue: {
        defaultValue: null,
        description: '',
        name: 'lowerValue',
        parent: {
          fileName: 'eui/src/components/form/range/range_highlight.tsx',
          name: 'EuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_highlight.tsx',
          name: 'EuiRangeHighlightProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      upperValue: {
        defaultValue: null,
        description: '',
        name: 'upperValue',
        parent: {
          fileName: 'eui/src/components/form/range/range_highlight.tsx',
          name: 'EuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_highlight.tsx',
          name: 'EuiRangeHighlightProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: {
          fileName: 'eui/src/components/form/range/range_highlight.tsx',
          name: 'EuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_highlight.tsx',
          name: 'EuiRangeHighlightProps'
        }],
        required: false,
        type: {
          name: '(e: React.MouseEvent<HTMLDivElement>) => void'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      levels: {
        defaultValue: null,
        description: 'Create colored indicators for certain intervals.\n' + 'An array of #EuiRangeLevel objects',
        name: 'levels',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeLevel[]'
        }
      },
      showTicks: {
        defaultValue: null,
        description: 'Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)',
        name: 'showTicks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['EuiRangeHighlightProps', '_SharedRangeValuesProps', '_SharedRangeDataStructures', '_SharedRangeVisualConfiguration']
  };
} catch (e) {}