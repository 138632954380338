/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { keys, useEuiTheme } from '../../services';
import { isDOMNode } from '../../utils';
import { EuiButtonIcon } from '../button';
import { EuiFocusTrap } from '../focus_trap';
import { EuiOverlayMask } from '../overlay_mask';
import { EuiI18n } from '../i18n';
import { euiModalStyles } from './modal.styles';
export var EuiModal = ({
  className,
  children,
  initialFocus,
  onClose,
  maxWidth = true,
  role = 'dialog',
  style,
  ...rest
}) => {
  const onKeyDown = event => {
    if (event.key === keys.ESCAPE) {
      if (isDOMNode(event.target) && event.currentTarget.contains(event.target)) {
        event.preventDefault();
        event.stopPropagation();
        onClose(event);
      }
    }
  };
  let newStyle = style;
  if (typeof maxWidth !== 'boolean') {
    newStyle = {
      ...newStyle,
      maxInlineSize: maxWidth
    };
  }
  const classes = classnames('euiModal', className);
  const euiTheme = useEuiTheme();
  const styles = euiModalStyles(euiTheme);
  const cssStyles = [styles.euiModal, maxWidth === true && styles.defaultMaxWidth];
  const cssCloseIconStyles = [styles.euiModal__closeIcon];
  return <EuiOverlayMask>
      <EuiFocusTrap initialFocus={initialFocus} scrollLock preventScrollOnFocus>
        <div css={cssStyles} className={classes} onKeyDown={onKeyDown} tabIndex={0} style={newStyle} role={role} aria-modal={true} {...rest}>
          <EuiI18n token="euiModal.closeModal" default="Closes this modal window">
            {closeModal => <EuiButtonIcon iconType="cross" onClick={onClose} css={cssCloseIconStyles} className="euiModal__closeIcon" color="text" aria-label={closeModal} />}
          </EuiI18n>
          {children}
        </div>
      </EuiFocusTrap>
    </EuiOverlayMask>;
};
EuiModal.propTypes = {
  className: PropTypes.string,
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  /**
     * Sets the max-width of the modal.
     * Set to `true` to use the default (`euiBreakpoints 'm'`),
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  maxWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),
  /**
     * Specifies what element should initially have focus.
     * Can be a DOM node, or a selector string (which will be passed to document.querySelector() to find the DOM node), or a function that returns a DOM node.
     */
  initialFocus: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.func.isRequired, PropTypes.string.isRequired]),
  /**
     * Identifies a modal dialog to screen readers. Modal dialogs that confirm destructive actions
     * or need a user's attention should use "alertdialog".
     */
  role: PropTypes.oneOf(["dialog", "alertdialog"])
};
try {
  EuiModal.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/modal/modal.tsx',
    description: '',
    displayName: 'EuiModal',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      onClose: {
        defaultValue: null,
        description: '',
        name: 'onClose',
        parent: {
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        }],
        required: true,
        type: {
          name: '(event?: any) => void'
        }
      },
      maxWidth: {
        defaultValue: {
          value: 'true'
        },
        description: 'Sets the max-width of the modal.\n' + "Set to `true` to use the default (`euiBreakpoints 'm'`),\n" + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'maxWidth',
        parent: {
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        }],
        required: false,
        type: {
          name: 'string | number | boolean'
        }
      },
      initialFocus: {
        defaultValue: null,
        description: 'Specifies what element should initially have focus.\n' + 'Can be a DOM node, or a selector string (which will be passed to document.querySelector() to find the DOM node), or a function that returns a DOM node.',
        name: 'initialFocus',
        parent: {
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        }],
        required: false,
        type: {
          name: 'string | HTMLElement | (() => HTMLElement)'
        }
      },
      role: {
        defaultValue: {
          value: 'dialog'
        },
        description: 'Identifies a modal dialog to screen readers. Modal dialogs that confirm destructive actions\n' + `or need a user's attention should use "alertdialog".`,
        name: 'role',
        parent: {
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"alertdialog" | "dialog"',
          value: [{
            value: '"alertdialog"'
          }, {
            value: '"dialog"'
          }]
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['EuiModalProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}