/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { findPopoverPosition, htmlIdGenerator, keys } from '../../services';
import { enqueueStateChange } from '../../services/react';
import { EuiResizeObserver } from '../observer/resize_observer';
import { EuiPortal } from '../portal';
import { EuiToolTipPopover } from './tool_tip_popover';
import { EuiToolTipAnchor } from './tool_tip_anchor';
import { EuiToolTipArrow } from './tool_tip_arrow';
import { toolTipManager } from './tool_tip_manager';
export var POSITIONS = ['top', 'right', 'bottom', 'left'];
var DISPLAYS = ['inlineBlock', 'block'];
var delayToMsMap = {
  regular: 250,
  long: 250 * 5
};
var DEFAULT_TOOLTIP_STYLES = {
  // position the tooltip content near the top-left
  // corner of the window so it can't create scrollbars
  // 50,50 because who knows what negative margins, padding, etc
  top: 50,
  left: 50,
  // just in case, avoid any potential flicker by hiding
  // the tooltip before it is positioned
  opacity: 0,
  // prevent accidental mouse interaction while positioning
  visibility: 'hidden'
};
export class EuiToolTip extends Component {
  _isMounted = false;
  anchor = null;
  popover = null;
  timeoutId;
  state = {
    visible: false,
    hasFocus: false,
    calculatedPosition: this.props.position,
    toolTipStyles: DEFAULT_TOOLTIP_STYLES,
    arrowStyles: undefined,
    id: this.props.id || htmlIdGenerator()()
  };
  static defaultProps = {
    position: 'top',
    delay: 'regular',
    display: 'inlineBlock'
  };
  clearAnimationTimeout = () => {
    if (this.timeoutId) {
      this.timeoutId = clearTimeout(this.timeoutId);
    }
  };
  componentDidMount() {
    this._isMounted = true;
    if (this.props.repositionOnScroll) {
      window.addEventListener('scroll', this.positionToolTip, true);
    }
  }
  componentWillUnmount() {
    this.clearAnimationTimeout();
    this._isMounted = false;
    window.removeEventListener('scroll', this.positionToolTip, true);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible === false && this.state.visible === true) {
      requestAnimationFrame(this.testAnchor);
    }

    // update scroll listener
    if (prevProps.repositionOnScroll !== this.props.repositionOnScroll) {
      if (this.props.repositionOnScroll) {
        window.addEventListener('scroll', this.positionToolTip, true);
      } else {
        window.removeEventListener('scroll', this.positionToolTip, true);
      }
    }
  }
  testAnchor = () => {
    // when the tooltip is visible, this checks if the anchor is still part of document
    // this fixes when the react root is removed from the dom without unmounting
    // https://github.com/elastic/eui/issues/1105
    if (document.body.contains(this.anchor) === false) {
      // the anchor is no longer part of `document`
      this.hideToolTip();
    } else {
      if (this.state.visible) {
        // if still visible, keep checking
        requestAnimationFrame(this.testAnchor);
      }
    }
  };
  setAnchorRef = ref => this.anchor = ref;
  setPopoverRef = ref => this.popover = ref;
  showToolTip = () => {
    if (!this.timeoutId) {
      this.timeoutId = setTimeout(() => {
        enqueueStateChange(() => {
          this.setState({
            visible: true
          });
          toolTipManager.registerTooltip(this.hideToolTip);
        });
      }, delayToMsMap[this.props.delay]);
    }
  };
  positionToolTip = () => {
    const requestedPosition = this.props.position;
    if (!this.anchor || !this.popover) {
      return;
    }
    const {
      position,
      left,
      top,
      arrow
    } = findPopoverPosition({
      anchor: this.anchor,
      popover: this.popover,
      position: requestedPosition,
      offset: 16,
      // offset popover 16px from the anchor
      arrowConfig: {
        arrowWidth: 12,
        arrowBuffer: 4
      }
    });

    // If encroaching the right edge of the window:
    // When `props.content` changes and is longer than `prevProps.content`, the tooltip width remains and
    // the resizeObserver callback will fire twice (once for vertical resize caused by text line wrapping,
    // once for a subsequent position correction) and cause a flash rerender and reposition.
    // To prevent this, we can orient from the right so that text line wrapping does not occur, negating
    // the second resizeObserver callback call.
    const windowWidth = document.documentElement.clientWidth || window.innerWidth;
    const useRightValue = windowWidth / 2 < left;
    const toolTipStyles = {
      top,
      left: useRightValue ? 'auto' : left,
      right: useRightValue ? windowWidth - left - this.popover.offsetWidth : 'auto'
    };
    this.setState({
      visible: true,
      calculatedPosition: position,
      toolTipStyles,
      arrowStyles: arrow
    });
  };
  hideToolTip = () => {
    this.clearAnimationTimeout();
    enqueueStateChange(() => {
      if (this._isMounted) {
        this.setState({
          visible: false,
          toolTipStyles: DEFAULT_TOOLTIP_STYLES,
          arrowStyles: undefined
        });
        toolTipManager.deregisterToolTip(this.hideToolTip);
      }
    });
  };
  onFocus = () => {
    this.setState({
      hasFocus: true
    });
    this.showToolTip();
  };
  onBlur = () => {
    this.setState({
      hasFocus: false
    });
    this.hideToolTip();
  };
  onEscapeKey = event => {
    if (event.key === keys.ESCAPE) {
      if (this.state.visible) event.stopPropagation();
      this.setState({
        hasFocus: false
      }); // Allows mousing over back into the tooltip to work correctly
      this.hideToolTip();
    }
  };
  onMouseOut = event => {
    // Prevent mousing over children from hiding the tooltip by testing for whether the mouse has
    // left the anchor for a non-child.
    if (this.anchor === event.relatedTarget || this.anchor != null && !this.anchor.contains(event.relatedTarget)) {
      if (!this.state.hasFocus) {
        this.hideToolTip();
      }
    }
    if (this.props.onMouseOut) {
      this.props.onMouseOut(event);
    }
  };
  render() {
    const {
      children,
      className,
      anchorClassName,
      anchorProps,
      content,
      title,
      delay,
      display,
      repositionOnScroll,
      ...rest
    } = this.props;
    const {
      arrowStyles,
      id,
      toolTipStyles,
      visible,
      calculatedPosition
    } = this.state;
    const classes = classNames('euiToolTip', className);
    const anchorClasses = classNames(anchorClassName, anchorProps?.className);
    return <>
        <EuiToolTipAnchor {...anchorProps} ref={this.setAnchorRef} onBlur={this.onBlur} onFocus={this.onFocus} onKeyDown={this.onEscapeKey} onMouseOver={this.showToolTip} onMouseOut={this.onMouseOut} id={id} className={anchorClasses} display={display} isVisible={visible}>
          {children}
        </EuiToolTipAnchor>
        {visible && (content || title) && <EuiPortal>
            <EuiToolTipPopover className={classes} style={toolTipStyles} positionToolTip={this.positionToolTip} popoverRef={this.setPopoverRef} title={title} id={id} role="tooltip" calculatedPosition={calculatedPosition} {...rest}>
              <EuiToolTipArrow style={arrowStyles} className="euiToolTip__arrow" position={calculatedPosition} />
              <EuiResizeObserver onResize={this.positionToolTip}>
                {resizeRef => <div ref={resizeRef}>{content}</div>}
              </EuiResizeObserver>
            </EuiToolTipPopover>
          </EuiPortal>}
      </>;
  }
}
EuiToolTip.propTypes = {
  /**
     * Passes onto the span wrapping the trigger.
     */
  anchorClassName: PropTypes.string,
  /**
     * Passes onto the span wrapping the trigger.
     */
  anchorProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  /**
     * The in-view trigger for your tooltip.
     */
  children: PropTypes.element.isRequired,
  /**
     * Passes onto the tooltip itself, not the trigger.
     */
  className: PropTypes.string,
  /**
     * The main content of your tooltip.
     */
  content: PropTypes.node,
  /**
     * Common display alternatives for the anchor wrapper
     */
  display: PropTypes.any,
  /**
     * Delay before showing tooltip. Good for repeatable items.
     */
  delay: PropTypes.oneOf(["regular", "long"]).isRequired,
  /**
     * An optional title for your tooltip.
     */
  title: PropTypes.node,
  /**
     * Unless you provide one, this will be randomly generated.
     */
  id: PropTypes.string,
  /**
     * Suggested position. If there is not enough room for it this will be changed.
     */
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]).isRequired,
  /**
     * When `true`, the tooltip's position is re-calculated when the user
     * scrolls. This supports having fixed-position tooltip anchors.
     *
     * When nesting an `EuiTooltip` in a scrollable container, `repositionOnScroll` should be `true`
     */
  repositionOnScroll: PropTypes.bool,
  /**
     * If supplied, called when mouse movement causes the tool tip to be
     * hidden.
     */
  onMouseOut: PropTypes.func,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiToolTip.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tool_tip/tool_tip.tsx',
    description: '',
    displayName: 'EuiToolTip',
    methods: [],
    props: {
      anchorClassName: {
        defaultValue: null,
        description: 'Passes onto the span wrapping the trigger.',
        name: 'anchorClassName',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      anchorProps: {
        defaultValue: null,
        description: 'Passes onto the span wrapping the trigger.',
        name: 'anchorProps',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLSpanElement>'
        }
      },
      children: {
        defaultValue: null,
        description: 'The in-view trigger for your tooltip.',
        name: 'children',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      className: {
        defaultValue: null,
        description: 'Passes onto the tooltip itself, not the trigger.',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      content: {
        defaultValue: null,
        description: 'The main content of your tooltip.',
        name: 'content',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      display: {
        defaultValue: {
          value: 'inlineBlock'
        },
        description: 'Common display alternatives for the anchor wrapper',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inlineBlock" | "block"',
          value: [{
            value: '"inlineBlock"'
          }, {
            value: '"block"'
          }]
        }
      },
      delay: {
        defaultValue: {
          value: 'regular'
        },
        description: 'Delay before showing tooltip. Good for repeatable items.',
        name: 'delay',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ToolTipDelay',
          value: [{
            value: '"regular"'
          }, {
            value: '"long"'
          }]
        }
      },
      title: {
        defaultValue: null,
        description: 'An optional title for your tooltip.',
        name: 'title',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      id: {
        defaultValue: null,
        description: 'Unless you provide one, this will be randomly generated.',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      position: {
        defaultValue: {
          value: 'top'
        },
        description: 'Suggested position. If there is not enough room for it this will be changed.',
        name: 'position',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ToolTipPositions',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }]
        }
      },
      repositionOnScroll: {
        defaultValue: null,
        description: "When `true`, the tooltip's position is re-calculated when the user\n" + 'scrolls. This supports having fixed-position tooltip anchors.\n' + '\n' + 'When nesting an `EuiTooltip` in a scrollable container, `repositionOnScroll` should be `true`',
        name: 'repositionOnScroll',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onMouseOut: {
        defaultValue: null,
        description: 'If supplied, called when mouse movement causes the tool tip to be\n' + 'hidden.',
        name: 'onMouseOut',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: '(event: MouseEvent<HTMLSpanElement, MouseEvent>) => void'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiToolTipProps', 'CommonProps']
  };
} catch (e) {}