/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from "prop-types";
import { useEuiTheme, useCombinedRefs } from '../../../services';
import { logicalStyles } from '../../../global_styling';
import { euiFormVariables } from '../form.styles';
import { EuiFieldNumber } from '../field_number';
import { euiRangeInputStyles } from './range_input.styles';
export var EuiRangeInput = ({
  min,
  max,
  step,
  value,
  inputRef,
  isInvalid,
  disabled,
  compressed,
  onChange,
  name,
  side = 'max',
  fullWidth,
  autoSize = true,
  ...rest
}) => {
  const euiTheme = useEuiTheme();
  const styles = euiRangeInputStyles(euiTheme);
  const cssStyles = [styles.euiRangeInput];

  // Determine whether an invalid icon is showing, which can come from
  // the underlying EuiFieldNumber's native :invalid state
  const [hasInvalidIcon, setHasInvalidIcon] = useState(isInvalid);
  const validityRef = useRef(null);
  const setRefs = useCombinedRefs([validityRef, inputRef]);
  useEffect(() => {
    const isNativelyInvalid = !validityRef.current?.validity.valid;
    setHasInvalidIcon(isNativelyInvalid || isInvalid);
  }, [value, isInvalid]);

  // Calculate the auto size width of the input
  const widthStyle = useMemo(() => {
    if (!autoSize) return undefined;

    // Calculate the number of characters to show (dynamic based on user input)
    // Uses the min/max char length as a max, then add an extra UX buffer of 1
    const maxChars = Math.max(String(min).length, String(max).length) + 1;
    const inputCharWidth = Math.min(String(value).length, maxChars);

    // Calculate the form padding based on `compressed` state
    const {
      controlPadding,
      controlCompressedPadding
    } = euiFormVariables(euiTheme);
    const inputPadding = compressed ? controlCompressedPadding : controlPadding;

    // Calculate the invalid icon (if being displayed), also based on `compressed` state
    const invalidIconWidth = hasInvalidIcon ? euiTheme.euiTheme.base * (compressed ? 1.125 : 1.375) // TODO: DRY this out once EuiFormControlLayoutIcons is converted to Emotion
    : 0;

    // Guesstimate a width for the stepper. Note that it's a little wider in FF than it is in Chrome
    const stepperWidth = 2;
    return logicalStyles({
      width: `calc(${inputPadding} + ${inputCharWidth}ch + ${stepperWidth}em + ${invalidIconWidth}px)`
    });
  }, [autoSize, euiTheme, compressed, hasInvalidIcon, min, max, value]);
  return <EuiFieldNumber name={name} className={`euiRangeInput euiRangeInput--${side}`} css={cssStyles} min={Number(min)} max={Number(max)} step={step} value={value === '' ? '' : Number(value)} inputRef={setRefs} isInvalid={isInvalid} disabled={disabled} compressed={compressed} onChange={onChange} style={widthStyle} fullWidth={fullWidth} {...rest} />;
};
EuiRangeInput.propTypes = {
  autoSize: PropTypes.bool,
  /**
     * @default 'max'
     */
  side: PropTypes.oneOf(["min", "max"])
};
try {
  EuiRangeInput.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/range_input.tsx',
    description: '',
    displayName: 'EuiRangeInput',
    methods: [],
    props: {
      autoSize: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'autoSize',
        parent: {
          fileName: 'eui/src/components/form/range/range_input.tsx',
          name: 'EuiRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_input.tsx',
          name: 'EuiRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      icon: {
        defaultValue: null,
        description: '',
        name: 'icon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'IconType | IconShape'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLInputElement>'
        }
      },
      readOnly: {
        defaultValue: null,
        description: '',
        name: 'readOnly',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      controlOnly: {
        defaultValue: null,
        description: 'Completely removes form control layout wrapper and ignores\n' + 'icon, prepend, and append. Best used inside EuiFormControlLayoutDelimited.',
        name: 'controlOnly',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number to increment or decrement between each interval',
        name: 'step',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SingleRangeValue'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SingleRangeValue'
        }],
        required: true,
        type: {
          name: 'string | number'
        }
      },
      side: {
        defaultValue: {
          value: 'max'
        },
        description: '',
        name: 'side',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"max" | "min"',
          value: [{
            value: '"max"'
          }, {
            value: '"min"'
          }]
        }
      }
    },
    extendedInterfaces: ['EuiRangeInputProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'InputHTMLAttributes', 'CommonProps', '_SharedRangeValuesProps', '_SingleRangeValue']
  };
} catch (e) {}