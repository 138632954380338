/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiFormRow, EuiFieldText } from '../form';
import { euiFormVariables } from '../form/form.styles';
import { EuiButtonIcon, EuiButtonEmpty } from '../button';
import { EuiFlexGroup, EuiFlexItem } from '../flex';
import { EuiSkeletonLoading, EuiSkeletonRectangle } from '../skeleton';
import { useEuiMemoizedStyles, useCombinedRefs, keys } from '../../services';
import { EuiI18n, useEuiI18n } from '../i18n';
import { useGeneratedHtmlId } from '../../services/accessibility';
import { euiInlineEditReadModeStyles } from './inline_edit_form.styles';

// Props shared between the internal form component as well as consumer-facing components

// Internal-only props, passed by the consumer-facing components

export var SMALL_SIZE_FORM = {
  iconSize: 's',
  compressed: true,
  buttonSize: 's'
};
export var MEDIUM_SIZE_FORM = {
  iconSize: 'm',
  compressed: false,
  buttonSize: 'm'
};
export var EuiInlineEditForm = ({
  className,
  children,
  sizes,
  defaultValue = '',
  value: controlledValue = '',
  onChange,
  onCancel,
  placeholder = '',
  inputAriaLabel,
  startWithEditOpen,
  readModeProps,
  editModeProps,
  isLoading = false,
  isInvalid,
  onSave,
  isReadOnly,
  ...rest
}) => {
  const classes = classNames('euiInlineEdit', className);
  const {
    controlHeight,
    controlCompressedHeight
  } = useEuiMemoizedStyles(euiFormVariables);
  const loadingSkeletonSize = sizes.compressed ? controlCompressedHeight : controlHeight;
  const defaultSaveButtonAriaLabel = useEuiI18n('euiInlineEditForm.saveButtonAriaLabel', 'Save edit');
  const defaultCancelButtonAriaLabel = useEuiI18n('euiInlineEditForm.cancelButtonAriaLabel', 'Cancel edit');
  const readModeDescribedById = useGeneratedHtmlId({
    prefix: 'inlineEdit'
  });
  const editModeDescribedById = useGeneratedHtmlId({
    prefix: 'inlineEdit'
  });
  const readModeFocusRef = useRef(null);
  const editModeFocusRef = useRef(null);
  const setReadModeRefs = useCombinedRefs([readModeFocusRef, readModeProps?.buttonRef]);
  const setEditModeRefs = useCombinedRefs([editModeFocusRef, editModeProps?.inputProps?.inputRef]);
  const [isEditing, setIsEditing] = useState(false || startWithEditOpen);
  const [editModeValue, setEditModeValue] = useState(defaultValue);

  // readModeValue accepts controlledValue here to provide a reliable backup for the onCancel callback
  const [readModeValue, setReadModeValue] = useState(controlledValue || defaultValue);
  const value = useMemo(() => {
    if (controlledValue) {
      return controlledValue;
    } else {
      return isEditing ? editModeValue : readModeValue || placeholder;
    }
  }, [controlledValue, editModeValue, readModeValue, isEditing, placeholder]);
  const readModeStyles = useEuiMemoizedStyles(euiInlineEditReadModeStyles);
  const readModeCssStyles = [readModeStyles.euiInlineEditReadMode, isReadOnly && readModeStyles.isReadOnly, placeholder && !readModeValue && readModeStyles.hasPlaceholder];
  const activateEditMode = () => {
    setIsEditing(true);
    // Waits a tick for state to settle and the focus target to render
    requestAnimationFrame(() => editModeFocusRef.current?.focus());
  };
  const cancelInlineEdit = () => {
    setEditModeValue(readModeValue);
    onCancel?.(readModeValue);
    setIsEditing(false);
    requestAnimationFrame(() => readModeFocusRef.current?.focus());
  };
  const saveInlineEditValue = async () => {
    // If an onSave callback is present, and returns false, stay in edit mode
    if (onSave) {
      const onSaveReturn = onSave(value);
      const awaitedReturn = onSaveReturn instanceof Promise ? await onSaveReturn : onSaveReturn;
      if (awaitedReturn === false) return;
    }
    setReadModeValue(editModeValue);
    setIsEditing(false);
    requestAnimationFrame(() => readModeFocusRef.current?.focus());
  };
  const editModeInputOnKeyDown = event => {
    switch (event.key) {
      case keys.ENTER:
        event.preventDefault(); // Enter keypresses will not proceed otherwise on webkit browsers & screen readers
        saveInlineEditValue();
        break;
      case keys.ESCAPE:
        cancelInlineEdit();
        break;
    }
  };

  // If the state of isReadOnly changes while in edit mode, switch back to read mode
  useEffect(() => {
    if (isReadOnly) {
      setIsEditing(false);
    }
  }, [isReadOnly]);
  const editModeForm = <EuiFlexGroup gutterSize="s" responsive={false}>
      <EuiFlexItem>
        <EuiFormRow fullWidth isInvalid={isInvalid} error={isInvalid && editModeProps?.formRowProps?.error} {...editModeProps?.formRowProps}>
          <EuiFieldText fullWidth value={value} aria-label={inputAriaLabel} compressed={sizes.compressed} isInvalid={isInvalid} isLoading={isLoading} data-test-subj="euiInlineEditModeInput" placeholder={placeholder || undefined} // Opt not to render the prop entirely if an empty string is passed
        {...editModeProps?.inputProps} inputRef={setEditModeRefs} onChange={e => {
          setEditModeValue(e.target.value);
          onChange?.(e);
          editModeProps?.inputProps?.onChange?.(e);
        }} onKeyDown={e => {
          editModeInputOnKeyDown(e);
          editModeProps?.inputProps?.onKeyDown?.(e);
        }} aria-describedby={classNames(editModeDescribedById, editModeProps?.inputProps?.['aria-describedby'])} />
        </EuiFormRow>
        <span id={editModeDescribedById} hidden>
          <EuiI18n token="euiInlineEditForm.inputKeyboardInstructions" default="Press Enter to save your edited text. Press Escape to cancel your edit." />
        </span>
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <EuiSkeletonLoading isLoading={isLoading} announceLoadingStatus={true} announceLoadedStatus={false} loadingContent={<EuiFlexGroup gutterSize="s">
              <EuiSkeletonRectangle height={loadingSkeletonSize} width={loadingSkeletonSize} borderRadius="m" />
              <EuiSkeletonRectangle height={loadingSkeletonSize} width={loadingSkeletonSize} borderRadius="m" />
            </EuiFlexGroup>} loadedContent={<EuiFlexGroup gutterSize="s">
              <EuiButtonIcon iconType="check" aria-label={defaultSaveButtonAriaLabel} color="success" display="base" size={sizes.buttonSize} iconSize={sizes.iconSize} data-test-subj="euiInlineEditModeSaveButton" {...editModeProps?.saveButtonProps} onClick={e => {
          saveInlineEditValue();
          editModeProps?.saveButtonProps?.onClick?.(e);
        }} />
              <EuiButtonIcon iconType="cross" aria-label={defaultCancelButtonAriaLabel} color="danger" display="base" size={sizes.buttonSize} iconSize={sizes.iconSize} data-test-subj="euiInlineEditModeCancelButton" {...editModeProps?.cancelButtonProps} onClick={e => {
          cancelInlineEdit();
          editModeProps?.cancelButtonProps?.onClick?.(e);
        }} />
            </EuiFlexGroup>} />
      </EuiFlexItem>
    </EuiFlexGroup>;
  const readModeElement = <>
      <EuiButtonEmpty color="text" iconType={isReadOnly ? undefined : 'pencil'} iconSide="right" flush="both" iconSize={sizes.iconSize} size={sizes.buttonSize} data-test-subj="euiInlineReadModeButton" disabled={isReadOnly} css={readModeCssStyles} title={value} {...readModeProps} buttonRef={setReadModeRefs} aria-describedby={classNames(readModeDescribedById, readModeProps?.['aria-describedby'])} onClick={e => {
      activateEditMode();
      readModeProps?.onClick?.(e);
    }}>
        {children(value)}
      </EuiButtonEmpty>
      <span id={readModeDescribedById} hidden>
        {!isReadOnly && <EuiI18n token="euiInlineEditForm.activateEditModeDescription" default="Click to edit this text inline." />}
      </span>
    </>;
  return <div className={classes} {...rest}>
      {isEditing ? editModeForm : readModeElement}
    </div>;
};
EuiInlineEditForm.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  placeholder: PropTypes.string,
  /**
       * Callback that fires when a user clicks the save button.
       * Passes the current edited text value as an argument.
       *
       * To validate the value of the edited text, pass back a boolean flag.
       * If `false`, EuiInlineEdit will remain in edit mode, where loading or invalid states can be set.
       * If `true`, EuiInlineEdit will return to read mode.
       */
  onSave: PropTypes.func,
  /**
       * Form label that appears above the form control.
       * This is required for accessibility because there is no visual label on the input.
       */
  inputAriaLabel: PropTypes.string.isRequired,
  /**
       * Starts the component in edit mode
       */
  startWithEditOpen: PropTypes.bool,
  /**
       * Props that will be applied directly to the `EuiEmptyButton` displayed in read mode
       */
  readModeProps: PropTypes.any,
  /**
       * Multiple props objects that can be applied directly to various child components displayed in edit mode.
       * - `formRowProps` will be passed to `EuiFormRow`
       * - `inputProps` will be passed to `EuiFieldText`
       * - `saveButtonProps` & `cancelButtonProps` will be passed to their respective `EuiIconButton`s
       */
  editModeProps: PropTypes.shape({
    formRowProps: PropTypes.any,
    inputProps: PropTypes.any,
    saveButtonProps: PropTypes.any,
    cancelButtonProps: PropTypes.any
  }),
  /**
       * Loading state - only displayed in edit mode
       */
  isLoading: PropTypes.bool,
  /**
       * Invalid state - only displayed edit mode
       */
  isInvalid: PropTypes.bool,
  /**
       * Locks inline edit in read mode and displays the text value
       */
  isReadOnly: PropTypes.bool,
  /**
         * Initial inline edit text value
         */
  defaultValue: PropTypes.string,
  /**
         * To use inline edit as a controlled component, continuously pass the value via this prop
         */
  value: PropTypes.string,
  /**
         * Callback required to receive and update `value` based on user input
         */
  onChange: PropTypes.func,
  /**
         * Callback required to reset `value` to the previous read mode text value.
         */
  onCancel: PropTypes.func,
  /**
     * Form sizes
     */
  sizes: PropTypes.shape({
    compressed: PropTypes.bool.isRequired,
    buttonSize: PropTypes.any.isRequired,
    iconSize: PropTypes.any.isRequired
  }).isRequired,
  /**
     * Render prop that returns the read mode value as an arg
     */
  children: PropTypes.func.isRequired
};
try {
  EuiInlineEditForm.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/inline_edit/inline_edit_form.tsx',
    description: '',
    displayName: 'EuiInlineEditForm',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      placeholder: {
        defaultValue: {
          value: ''
        },
        description: '',
        name: 'placeholder',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onSave: {
        defaultValue: null,
        description: 'Callback that fires when a user clicks the save button.\n' + 'Passes the current edited text value as an argument.\n' + '\n' + 'To validate the value of the edited text, pass back a boolean flag.\n' + 'If `false`, EuiInlineEdit will remain in edit mode, where loading or invalid states can be set.\n' + 'If `true`, EuiInlineEdit will return to read mode.',
        name: 'onSave',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(value: string) => boolean | void | Promise<boolean | void>'
        }
      },
      inputAriaLabel: {
        defaultValue: null,
        description: 'Form label that appears above the form control.\n' + 'This is required for accessibility because there is no visual label on the input.',
        name: 'inputAriaLabel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      startWithEditOpen: {
        defaultValue: null,
        description: 'Starts the component in edit mode',
        name: 'startWithEditOpen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      readModeProps: {
        defaultValue: null,
        description: 'Props that will be applied directly to the `EuiEmptyButton` displayed in read mode',
        name: 'readModeProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<EuiButtonEmptyPropsForButton>'
        }
      },
      editModeProps: {
        defaultValue: null,
        description: 'Multiple props objects that can be applied directly to various child components displayed in edit mode.\n' + '- `formRowProps` will be passed to `EuiFormRow`\n' + '- `inputProps` will be passed to `EuiFieldText`\n' + '- `saveButtonProps` & `cancelButtonProps` will be passed to their respective `EuiIconButton`s',
        name: 'editModeProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '{ formRowProps?: Partial<(DisambiguateSet<LabelProps, LegendProps> & { labelType?: "legend"; } & CommonProps & { display?: "row" | "center" | "columnCompressed" | "centerCompressed" | "rowCompressed"; ... 11 more ...; isDisabled?: boolean; } & Omit<...>) | (DisambiguateSet<...> & ... 3 more ... & HTMLAttributes<...>...'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: 'Loading state - only displayed in edit mode',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: 'Invalid state - only displayed edit mode',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isReadOnly: {
        defaultValue: null,
        description: 'Locks inline edit in read mode and displays the text value',
        name: 'isReadOnly',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      value: {
        defaultValue: null,
        description: 'To use inline edit as a controlled component, continuously pass the value via this prop',
        name: 'value',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onCancel: {
        defaultValue: null,
        description: 'Callback required to reset `value` to the previous read mode text value.',
        name: 'onCancel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(perviousValue: string) => void'
        }
      },
      sizes: {
        defaultValue: null,
        description: 'Form sizes',
        name: 'sizes',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '{ compressed: boolean; buttonSize: "s" | "m" | "xs"; iconSize: "s" | "m"; }'
        }
      },
      children: {
        defaultValue: null,
        description: 'Render prop that returns the read mode value as an arg',
        name: 'children',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(readModeValue: ReactNode) => ReactNode'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}