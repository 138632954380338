function _EMOTION_STRINGIFIED_CSS_ERROR__() {
  return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
}
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.includes.js";
import PropTypes from "prop-types";
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import React from 'react';
import chroma from 'chroma-js';
import { EuiText, EuiFlexGrid, EuiCopy, EuiFlexItem, useEuiTheme, EuiPanel, EuiHorizontalRule, EuiListGroupItem } from '../../../../../src';
import { getContrastRatings } from './_contrast_utilities';
import { brandKeys, brandTextKeys, shadeKeys, textKeys } from './_color_js';
var textColors = [].concat(_toConsumableArray(textKeys), ['disabledText', 'ghost', 'ink']);
var allowedColors = [].concat(_toConsumableArray(brandKeys), _toConsumableArray(shadeKeys), ['disabled', 'ghost', 'ink']);
var textVariants = [].concat(_toConsumableArray(brandTextKeys), _toConsumableArray(textColors));
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "18g8xai-ColorSectionJS",
  styles: "grid-template-rows:auto;grid-auto-flow:row;label:ColorSectionJS;"
} : {
  name: "18g8xai-ColorSectionJS",
  styles: "grid-template-rows:auto;grid-auto-flow:row;label:ColorSectionJS;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export var ColorSectionJS = function ColorSectionJS(_ref) {
  var color = _ref.color,
    _colorValue = _ref.colorValue,
    minimumContrast = _ref.minimumContrast,
    showTextVariants = _ref.showTextVariants,
    matchPanelColor = _ref.matchPanelColor,
    hookName = _ref.hookName;
  var _useEuiTheme = useEuiTheme(),
    euiTheme = _useEuiTheme.euiTheme;
  var colorsForContrast = showTextVariants ? textVariants : allowedColors;
  function colorIsCore(color) {
    return brandKeys.includes(color) || brandTextKeys.includes(color);
  }
  var colorValue = _colorValue || euiTheme.colors[color];
  return ___EmotionJSX(EuiPanel, {
    color: "transparent",
    hasBorder: false,
    paddingSize: matchPanelColor ? 'l' : 'none',
    style: {
      background: matchPanelColor ? colorValue : undefined
    }
  }, ___EmotionJSX(EuiText, {
    size: "xs"
  }, ___EmotionJSX(EuiFlexGrid, {
    css: _ref2,
    columns: 2,
    direction: "column",
    gutterSize: "s"
  }, showTextVariants && colorIsCore(colorValue) && ___EmotionJSX(ColorsContrastItem, {
    foreground: "".concat(color, "Text"),
    background: 'body',
    minimumContrast: minimumContrast
  }), colorsForContrast.map(function (color2) {
    if (colorIsCore(colorValue) && colorIsCore(color2)) {
      // i.e. don't render if both are core colors
      return;
    }
    return ___EmotionJSX(ColorsContrastItem, {
      foreground: color2,
      background: _colorValue ? colorValue : color,
      key: color2,
      minimumContrast: minimumContrast,
      styleString: hookName && "".concat(hookName, "('").concat(color, "')")
    });
  }))));
};
ColorSectionJS.propTypes = {
  color: PropTypes.any.isRequired,
  colorValue: PropTypes.string,
  minimumContrast: PropTypes.number.isRequired,
  showTextVariants: PropTypes.bool.isRequired,
  matchPanelColor: PropTypes.bool,
  hookName: PropTypes.string
};
var ColorsContrastItem = ({
  foreground,
  background,
  minimumContrast,
  styleString
}) => {
  const {
    euiTheme
  } = useEuiTheme();
  const backgroundColorIsToken = euiTheme.colors[background];
  const backgroundColor = backgroundColorIsToken || background;
  const foregroundColorIsToken = euiTheme.colors[foreground];
  const foregroundColor = foregroundColorIsToken || foreground;
  const backgroundIsBody = background === 'body';
  const contrast = chroma.contrast(backgroundColor, foregroundColor);
  if (!contrast || contrast < minimumContrast) {
    return <></>;
  }
  const {
    contrastRating,
    contrastRatingBadge
  } = getContrastRatings(contrast, foreground);
  const backgroundStyleString = styleString || `euiTheme.colors.${background}`;
  const contrastIsAcceptableToCopy = contrast >= 3;
  const textToCopy = backgroundIsBody ? `color: $\{euiTheme.colors.${foreground}};` : `background-color: $\{${backgroundStyleString}};
color: $\{euiTheme.colors.${foreground}};`;
  const beforeMessage = contrastIsAcceptableToCopy ? <small>
      <code>{`euiTheme.colors.${foreground} / ${backgroundStyleString}`}</code>
      <EuiHorizontalRule margin="xs" />
      <kbd>Click</kbd> to copy CSS-in-JS configuration
    </small> : <small>
      Cannot copy configuration because the contrast is not acceptable
    </small>;
  return <EuiFlexItem className="eui-textCenter">
      <EuiCopy display="block" title={<span>
            {contrastRatingBadge} Contrast is {contrast.toFixed(1)}
          </span>} beforeMessage={beforeMessage} afterMessage={<small>Copied!</small>} textToCopy={textToCopy}>
        {copy => <EuiListGroupItem iconType={contrastRating} size="s" onClick={contrastIsAcceptableToCopy ? copy : undefined} aria-label={contrastIsAcceptableToCopy ? 'Click to copy CSS-in-JS configurations' : undefined} style={{
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRadius: euiTheme.border.radius.medium
      }}
      // @ts-expect-error - this isn't a valid color type, we mostly just want to disable the default EuiListGroupItem button color from being rendered since we're setting our own via `style`
      color="inherit" label={sanitizeColorName(foreground)} />}
      </EuiCopy>
    </EuiFlexItem>;
};
ColorsContrastItem.propTypes = {
  foreground: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  minimumContrast: PropTypes.number.isRequired,
  styleString: PropTypes.string
};
function sanitizeColorName(color) {
  // Add space between caplital letters
  const spaced = color.replace(/([A-Z])/g, ' $1').trim();
  // Capitalize first letter
  return spaced.charAt(0).toUpperCase() + spaced.slice(1);
}
try {
  ColorSectionJS.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/color/_contrast_js.tsx',
    description: '',
    displayName: 'ColorSectionJS',
    methods: [],
    props: {
      color: {
        defaultValue: null,
        description: '',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_js.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: 'keyof _EuiThemeBrandColors | keyof _EuiThemeBrandTextColors | keyof _EuiThemeShadeColors | keyof _EuiThemeSpecialColors | keyof _EuiThemeTextColors',
          value: [{
            value: '"highlight"'
          }, {
            value: '"title"'
          }, {
            value: '"link"'
          }, {
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"disabled"'
          }, {
            value: '"body"'
          }, {
            value: '"shadow"'
          }, {
            value: '"primaryText"'
          }, {
            value: '"accentText"'
          }, {
            value: '"successText"'
          }, {
            value: '"warningText"'
          }, {
            value: '"dangerText"'
          }, {
            value: '"emptyShade"'
          }, {
            value: '"lightestShade"'
          }, {
            value: '"lightShade"'
          }, {
            value: '"mediumShade"'
          }, {
            value: '"darkShade"'
          }, {
            value: '"darkestShade"'
          }, {
            value: '"fullShade"'
          }, {
            value: '"disabledText"'
          }, {
            value: '"subduedText"'
          }]
        }
      },
      colorValue: {
        defaultValue: null,
        description: '',
        name: 'colorValue',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_js.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      minimumContrast: {
        defaultValue: null,
        description: '',
        name: 'minimumContrast',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_js.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      showTextVariants: {
        defaultValue: null,
        description: '',
        name: 'showTextVariants',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_js.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      matchPanelColor: {
        defaultValue: null,
        description: '',
        name: 'matchPanelColor',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_js.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hookName: {
        defaultValue: null,
        description: '',
        name: 'hookName',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_js.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}