function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
import PropTypes from "prop-types";
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useGeneratedHtmlId, useEuiMemoizedStyles } from '../../services';
import { logicalSizeStyle, useEuiPaddingSize, useEuiPaddingCSS } from '../../global_styling';
import { useEuiResizableContainerContext } from './context';
import { EuiPanel } from '../panel';
import { useEuiI18n } from '../i18n';
import { EuiResizableCollapseButton } from './resizable_collapse_button';
import { euiResizablePanelStyles as styles, euiResizablePanelContentStyles } from './resizable_panel.styles';
var defaultToggleOptions = {
  className: null,
  'data-test-subj': undefined,
  position: 'middle'
};
export var getModeType = function getModeType(mode) {
  return _typeof(mode) === 'object' ? mode[0] : mode;
};
export var getToggleOptions = function getToggleOptions(mode) {
  return _typeof(mode) === 'object' ? _objectSpread(_objectSpread({}, defaultToggleOptions), mode[1]) : defaultToggleOptions;
};
var getPosition = function getPosition(ref) {
  var position = 'middle';
  if (ref.matches(':first-of-type')) {
    position = 'first';
  } else if (ref.matches(':last-of-type')) {
    position = 'last';
  }
  return position;
};
export var EuiResizablePanel = ({
  children,
  className,
  css,
  id,
  isHorizontal,
  size,
  initialSize,
  minSize = '0px',
  // Actual minSize is calculated in `./helpers.ts`
  scrollable = true,
  mode,
  registration,
  onToggleCollapsed,
  onToggleCollapsedInternal,
  wrapperProps,
  hasShadow = false,
  borderRadius = 'none',
  color = 'transparent',
  paddingSize = 'm',
  wrapperPadding = 'none',
  ...rest
}) => {
  const {
    registry: {
      panels,
      resizers
    } = {
      panels: {},
      resizers: {}
    }
  } = useEuiResizableContainerContext();
  const divRef = useRef(null);
  const panelId = useGeneratedHtmlId({
    prefix: 'resizable-panel',
    conditionalId: id
  });
  const resizerIds = useRef([]);
  const modeType = useMemo(() => getModeType(mode), [mode]);
  const toggleOpts = useMemo(() => getToggleOptions(mode), [mode]);
  const innerSize = useMemo(() => (panels[panelId] && panels[panelId].size) ?? (initialSize || 0), [panels, panelId, initialSize]);
  const isCollapsed = useMemo(() => panels[panelId] && panels[panelId].isCollapsed || false, [panels, panelId]);
  const position = useMemo(() => panels[panelId] && panels[panelId].position || 'middle', [panels, panelId]);
  const isCollapsible = useMemo(() => modeType === 'collapsible', [modeType]);
  const direction = useMemo(() => {
    let direction = null;
    if (position === 'middle' && (isCollapsible || isCollapsed)) {
      const ids = Object.keys(panels);
      const index = ids.indexOf(panelId);
      const prevPanel = panels[ids[index - 1]];
      const nextPanel = panels[ids[index + 1]];
      const prevPanelMode = prevPanel ? getModeType(prevPanel.mode) : null;
      const nextPanelMode = nextPanel ? getModeType(nextPanel.mode) : null;
      // Intentional, preferential order
      if (prevPanelMode === 'main') {
        direction = 'right';
      } else if (nextPanelMode === 'main') {
        direction = 'left';
      } else if (prevPanelMode && prevPanelMode !== 'collapsible') {
        direction = 'right';
      } else if (nextPanelMode && nextPanelMode !== 'collapsible') {
        direction = 'left';
      } else if (prevPanel && nextPanel) {
        direction = prevPanel.size > nextPanel.size ? 'right' : 'left';
      } else if (prevPanel) {
        direction = 'right';
      } else if (nextPanel) {
        direction = 'left';
      }
    }
    return direction;
  }, [isCollapsed, isCollapsible, position, panels, panelId]);
  const axis = isHorizontal ? 'horizontal' : 'vertical';
  const cssStyles = [styles.euiResizablePanel, isCollapsed && styles.collapsed, useEuiPaddingCSS()[wrapperPadding], wrapperProps?.css];
  const contentStyles = useEuiMemoizedStyles(euiResizablePanelContentStyles);
  const contentCssStyles = [contentStyles.euiResizablePanel__content, scrollable && contentStyles.scrollable, isCollapsed && contentStyles.collapsedChildren, isCollapsed && !isCollapsible && contentStyles[axis].collapsed, isCollapsible && contentStyles[axis].hasCollapsibleButton];
  const classes = classNames('euiResizablePanel', wrapperProps?.className);
  const panelClasses = classNames('euiResizablePanel__content', className);
  const inlineStyles = {
    ...wrapperProps?.style,
    ...(isHorizontal ? logicalSizeStyle(`${size || innerSize}%`, 'auto') : logicalSizeStyle('100%', `${size || innerSize}%`))
  };
  const padding = useEuiPaddingSize(paddingSize) || '0px';
  useEffect(() => {
    if (!registration) return;
    const initSize = size ?? (initialSize || 0);
    resizerIds.current = [divRef.current.previousElementSibling ? divRef.current.previousElementSibling.id : '', divRef.current.nextElementSibling ? divRef.current.nextElementSibling.id : ''];
    registration.register({
      id: panelId,
      size: initSize,
      prevSize: initSize,
      getSizePx() {
        return isHorizontal ? divRef.current.getBoundingClientRect().width : divRef.current.getBoundingClientRect().height;
      },
      minSize: [minSize, padding],
      mode: modeType,
      isCollapsed: false,
      position: getPosition(divRef.current)
    });
    return () => {
      registration.deregister(panelId);
    };
  }, [initialSize, isHorizontal, minSize, size, registration, modeType, padding, panelId]);
  const onClickCollapse = options => {
    onToggleCollapsedInternal && onToggleCollapsedInternal(panelId, options);
    onToggleCollapsed && onToggleCollapsed(panelId, options);
  };
  const collapseRight = e => {
    onClickCollapse({
      direction: 'right'
    });
    if (e.detail) e.currentTarget.blur();
  };
  const collapseLeft = e => {
    onClickCollapse({
      direction: 'left'
    });
    if (e.detail) e.currentTarget.blur();
  };
  const toggleButtonAriaLabel = useEuiI18n('euiResizablePanel.toggleButtonAriaLabel', 'Press to toggle this panel');
  const hasLeftToggle = (isCollapsible || isCollapsed) && (position === 'last' || position === 'middle' && direction === 'right');
  const hasRightToggle = (isCollapsible || isCollapsed) && (position === 'first' || position === 'middle' && direction === 'left');
  const hasVisibleToggle = modeType === 'custom' && isCollapsed || isCollapsible;
  let theToggle;
  let theResizer;
  if ((isCollapsible || modeType === 'custom') && hasLeftToggle) {
    theResizer = resizers[resizerIds.current[0]];
    theToggle = <EuiResizableCollapseButton externalPosition="before" direction={axis} isVisible={theResizer && (theResizer.isFocused || theResizer.isDisabled)} isCollapsed={theResizer && theResizer.isDisabled} internalPosition={toggleOpts.position} data-test-subj={toggleOpts['data-test-subj']} aria-label={toggleButtonAriaLabel} onClick={collapseRight} />;
  } else if ((isCollapsible || modeType === 'custom') && hasRightToggle) {
    theResizer = resizers[resizerIds.current[1]];
    theToggle = <EuiResizableCollapseButton externalPosition="after" direction={axis} isVisible={theResizer && (theResizer.isFocused || theResizer.isDisabled)} isCollapsed={theResizer && theResizer.isDisabled} internalPosition={toggleOpts.position} data-test-subj={toggleOpts['data-test-subj']} aria-label={toggleButtonAriaLabel} onClick={collapseLeft} />;
  }
  return <div {...wrapperProps} css={cssStyles} id={panelId} ref={divRef} style={inlineStyles} className={classes} data-collapsed={isCollapsed || undefined} data-position={position}>
      {/* The toggle is displayed on either side for tab order */}
      {hasVisibleToggle && hasLeftToggle && theToggle}
      <EuiPanel css={contentCssStyles} className={panelClasses} hasShadow={hasShadow} borderRadius={borderRadius} color={color} paddingSize={isCollapsed ? 'none' : paddingSize} {...rest}>
        {children}
      </EuiPanel>
      {/* The toggle is displayed on either side for tab order */}
      {hasVisibleToggle && hasRightToggle && theToggle}
    </div>;
};
EuiResizablePanel.propTypes = {
  /**
     * Specify a desired minimum panel size in pixels or percents,
     * for example "300px" or "30%"
     * The actual minimum size will be calculated,
     * using the larger of this prop and the panelProps.paddingSize
     */
  minSize: PropTypes.string,
  /**
     * Specify id of panel if you want to track panel size in "onPanelWidthChange" callback
     */
  id: PropTypes.string,
  /**
     * Initial size of the panel in percents
     * Specify this prop if you don't need to handle the panel size from outside
     */
  initialSize: PropTypes.number,
  /**
     * Size of the panel in percents.
     * Specify this prop if you want to control the size from outside, the panel will ignore the "initialSize"
     */
  size: PropTypes.number,
  /**
     * Add Eui scroll and overflow for the panel
     */
  scrollable: PropTypes.bool,
  /*
     * For use with collapsible panels.
     * Specify either `'collapsible'`, `'main'`, or `'custom'`.
     * `'collapsible'` also accepts an array where
     * the second item is attributes for the toggle button:
     * `['collapsible', {'data-test-subj'?: string, className?: string;}]
     */
  mode: PropTypes.oneOfType([PropTypes.oneOf(["collapsible", "main", "custom"]).isRequired, PropTypes.any.isRequired]),
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,
  /**
     * Custom CSS properties applied to the wrapping `.euiResizablePanel` div
     */
  style: PropTypes.any,
  /**
     * tabIndex={0} provides full keyboard access when content overflows `<EuiResizablePanel />`
     */
  tabIndex: PropTypes.number,
  /**
     * Props to add to the wrapping `.euiResizablePanel` div
     */
  wrapperProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  /**
     * Padding to add directly to the wrapping `.euiResizablePanel` div
     * Gives space around the actual panel.
     */
  wrapperPadding: PropTypes.any,
  /**
     * Adds a medium shadow to the panel;
     * Only works when `color="plain"`
     */
  hasShadow: PropTypes.bool,
  /**
     * Adds a slight 1px border on all edges.
     * Only works when `color="plain | transparent"`
     */
  hasBorder: PropTypes.bool,
  /**
     * Padding for all four sides
     */
  paddingSize: PropTypes.any,
  /**
     * Corner border radius
     */
  borderRadius: PropTypes.any,
  /**
     * When true the panel will grow in height to match `EuiFlexItem`
     */
  grow: PropTypes.bool,
  panelRef: PropTypes.any,
  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */
  color: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
export function euiResizablePanelWithControls(controls) {
  return props => <EuiResizablePanel {...controls} {...props} />;
}
try {
  euiResizablePanelWithControls.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/resizable_container/resizable_panel.tsx',
    description: '',
    displayName: 'euiResizablePanelWithControls',
    methods: [],
    props: {
      isHorizontal: {
        defaultValue: null,
        description: '',
        name: 'isHorizontal',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      registration: {
        defaultValue: null,
        description: '',
        name: 'registration',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        }],
        required: true,
        type: {
          name: '{ register: (panel: EuiResizablePanelController) => void; deregister: (panelId: string) => void; }'
        }
      },
      onToggleCollapsed: {
        defaultValue: null,
        description: '#ToggleCollapseCallback',
        name: 'onToggleCollapsed',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        }],
        required: false,
        type: {
          name: 'ToggleCollapseCallback'
        }
      },
      onToggleCollapsedInternal: {
        defaultValue: null,
        description: '',
        name: 'onToggleCollapsedInternal',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        }],
        required: true,
        type: {
          name: 'ToggleCollapseCallback'
        }
      }
    },
    extendedInterfaces: ['EuiResizablePanelControls', 'String', 'RelativeIndexable', 'Object', 'Array', 'EuiResizablePanelProps', '_EuiPanelProps', 'CommonProps']
  };
} catch (e) {}
try {
  getModeType.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/resizable_container/resizable_panel.tsx',
    description: '',
    displayName: 'getModeType',
    methods: [],
    props: {
      '0': {
        defaultValue: null,
        description: '',
        name: '0',
        parent: undefined,
        declarations: undefined,
        required: true,
        type: {
          name: 'string'
        }
      },
      '1': {
        defaultValue: null,
        description: '',
        name: '1',
        parent: undefined,
        declarations: undefined,
        required: true,
        type: {
          name: 'string | Partial<ToggleOptions>'
        }
      }
    },
    extendedInterfaces: ['EuiResizablePanelControls', 'String', 'RelativeIndexable', 'Object', 'Array', 'EuiResizablePanelProps', '_EuiPanelProps', 'CommonProps']
  };
} catch (e) {}
try {
  getToggleOptions.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/resizable_container/resizable_panel.tsx',
    description: '',
    displayName: 'getToggleOptions',
    methods: [],
    props: {
      '0': {
        defaultValue: null,
        description: '',
        name: '0',
        parent: undefined,
        declarations: undefined,
        required: true,
        type: {
          name: 'string'
        }
      },
      '1': {
        defaultValue: null,
        description: '',
        name: '1',
        parent: undefined,
        declarations: undefined,
        required: true,
        type: {
          name: 'string | Partial<ToggleOptions>'
        }
      }
    },
    extendedInterfaces: ['EuiResizablePanelControls', 'String', 'RelativeIndexable', 'Object', 'Array', 'EuiResizablePanelProps', '_EuiPanelProps', 'CommonProps']
  };
} catch (e) {}
try {
  EuiResizablePanel.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/resizable_container/resizable_panel.tsx',
    description: '',
    displayName: 'EuiResizablePanel',
    methods: [],
    props: {
      minSize: {
        defaultValue: {
          value: '0px'
        },
        description: 'Specify a desired minimum panel size in pixels or percents,\n' + 'for example "300px" or "30%"\n' + 'The actual minimum size will be calculated,\n' + 'using the larger of this prop and the panelProps.paddingSize',
        name: 'minSize',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      id: {
        defaultValue: null,
        description: 'Specify id of panel if you want to track panel size in "onPanelWidthChange" callback',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      initialSize: {
        defaultValue: null,
        description: 'Initial size of the panel in percents\n' + "Specify this prop if you don't need to handle the panel size from outside",
        name: 'initialSize',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      size: {
        defaultValue: null,
        description: 'Size of the panel in percents.\n' + 'Specify this prop if you want to control the size from outside, the panel will ignore the "initialSize"',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      scrollable: {
        defaultValue: {
          value: 'true'
        },
        description: 'Add Eui scroll and overflow for the panel',
        name: 'scrollable',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      mode: {
        defaultValue: null,
        description: '',
        name: 'mode',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'ModeOptions'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      style: {
        defaultValue: null,
        description: 'Custom CSS properties applied to the wrapping `.euiResizablePanel` div',
        name: 'style',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      tabIndex: {
        defaultValue: null,
        description: 'tabIndex={0} provides full keyboard access when content overflows `<EuiResizablePanel />`',
        name: 'tabIndex',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      wrapperProps: {
        defaultValue: null,
        description: 'Props to add to the wrapping `.euiResizablePanel` div',
        name: 'wrapperProps',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLDivElement>'
        }
      },
      wrapperPadding: {
        defaultValue: {
          value: 'none'
        },
        description: 'Padding to add directly to the wrapping `.euiResizablePanel` div\n' + 'Gives space around the actual panel.',
        name: 'wrapperPadding',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "s" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"none"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      hasShadow: {
        defaultValue: {
          value: 'false'
        },
        description: 'Adds a medium shadow to the panel;\nOnly works when `color="plain"`',
        name: 'hasShadow',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasBorder: {
        defaultValue: null,
        description: 'Adds a slight 1px border on all edges.\n' + 'Only works when `color="plain | transparent"`',
        name: 'hasBorder',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'm'
        },
        description: 'Padding for all four sides',
        name: 'paddingSize',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "s" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"none"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      borderRadius: {
        defaultValue: {
          value: 'none'
        },
        description: 'Corner border radius',
        name: 'borderRadius',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "m"',
          value: [{
            value: '"none"'
          }, {
            value: '"m"'
          }]
        }
      },
      grow: {
        defaultValue: null,
        description: 'When true the panel will grow in height to match `EuiFlexItem`',
        name: 'grow',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      color: {
        defaultValue: {
          value: 'transparent'
        },
        description: 'Background color of the panel;\n' + 'Usually a lightened form of the brand colors',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"transparent" | "accent" | "primary" | "success" | "warning" | "danger" | "subdued" | "plain"',
          value: [{
            value: '"transparent"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"plain"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      isHorizontal: {
        defaultValue: null,
        description: '',
        name: 'isHorizontal',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      registration: {
        defaultValue: null,
        description: '',
        name: 'registration',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        }],
        required: false,
        type: {
          name: '{ register: (panel: EuiResizablePanelController) => void; deregister: (panelId: string) => void; }'
        }
      },
      onToggleCollapsed: {
        defaultValue: null,
        description: '#ToggleCollapseCallback',
        name: 'onToggleCollapsed',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        }],
        required: false,
        type: {
          name: 'ToggleCollapseCallback'
        }
      },
      onToggleCollapsedInternal: {
        defaultValue: null,
        description: '',
        name: 'onToggleCollapsedInternal',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_panel.tsx',
          name: 'EuiResizablePanelControls'
        }],
        required: false,
        type: {
          name: 'ToggleCollapseCallback'
        }
      }
    },
    extendedInterfaces: ['EuiResizablePanelControls', 'String', 'RelativeIndexable', 'Object', 'Array', 'EuiResizablePanelProps', '_EuiPanelProps', 'CommonProps']
  };
} catch (e) {}