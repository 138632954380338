/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiButtonIcon } from '../button/button_icon';
import { keysOf } from '../common';
import { useEuiI18n } from '../i18n';
import { useEuiTheme } from '../../services';
import { euiPaginationButtonStyles } from './pagination_button.styles';
var typeToIconTypeMap = {
  first: 'arrowStart',
  previous: 'arrowLeft',
  next: 'arrowRight',
  last: 'arrowEnd'
};
export var TYPES = keysOf(typeToIconTypeMap);
export var EuiPaginationButtonArrow = ({
  className,
  type,
  disabled,
  ariaControls,
  onClick
}) => {
  const euiTheme = useEuiTheme();
  const styles = euiPaginationButtonStyles(euiTheme);
  const labels = {
    first: useEuiI18n('euiPaginationButtonArrow.firstPage', 'First page'),
    previous: useEuiI18n('euiPaginationButtonArrow.previousPage', 'Previous page'),
    next: useEuiI18n('euiPaginationButtonArrow.nextPage', 'Next page'),
    last: useEuiI18n('euiPaginationButtonArrow.lastPage', 'Last page')
  };
  const buttonProps = {};
  if (ariaControls && !disabled) {
    buttonProps.href = `#${ariaControls}`;
    buttonProps['aria-controls'] = ariaControls;
  }
  return <EuiButtonIcon css={styles.euiPaginationButton} className={classNames('euiPaginationArrowButton', className)} color="text" aria-label={labels[type]} title={disabled ? undefined : labels[type]} isDisabled={disabled} onClick={onClick} data-test-subj={`pagination-button-${type}`} iconType={typeToIconTypeMap[type]} {...buttonProps} />;
};
EuiPaginationButtonArrow.propTypes = {
  type: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  ariaControls: PropTypes.string
};
try {
  EuiPaginationButtonArrow.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/pagination/pagination_button_arrow.tsx',
    description: '',
    displayName: 'EuiPaginationButtonArrow',
    methods: [],
    props: {
      color: {
        defaultValue: null,
        description: 'Any of the named color palette options.',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "accent" | "primary" | "success" | "warning" | "danger"',
          value: [{
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: 'Identifies the element (or elements) that labels the current element.\n' + '@see aria-describedby.',
        name: 'aria-labelledby',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }],
        required: false,
        type: {
          name: 'MouseEventHandler<HTMLAnchorElement>'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: null,
        description: 'Overall size of button.\nMatches the sizes of other EuiButtons',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }]
        }
      },
      isSelected: {
        defaultValue: null,
        description: 'Applies the boolean state as the `aria-pressed` property to create a toggle button.\n' + '*Only use when the readable text does not change between states.*',
        name: 'isSelected',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Disables the button and changes the icon to a loading spinner',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      iconType: {
        defaultValue: null,
        description: '',
        name: 'iconType',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      iconSize: {
        defaultValue: null,
        description: 'Size of the icon only.\n' + 'This will not affect the overall size of the button',
        name: 'iconSize',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl" | "original" | "xxl"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }, {
            value: '"original"'
          }, {
            value: '"xxl"'
          }]
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '',
        name: 'isDisabled',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      href: {
        defaultValue: null,
        description: '',
        name: 'href',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AnchorHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLAnchorElement>'
        }
      },
      display: {
        defaultValue: null,
        description: 'Sets the display style for matching other EuiButton types.\n' + '`base` is equivalent to a typical EuiButton\n' + '`fill` is equivalent to a filled EuiButton\n' + '`empty` (default) is equivalent to an EuiButtonEmpty',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fill" | "empty" | "base"',
          value: [{
            value: '"fill"'
          }, {
            value: '"empty"'
          }, {
            value: '"base"'
          }]
        }
      },
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/pagination/pagination_button_arrow.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: '"next" | "previous" | "first" | "last"',
          value: [{
            value: '"next"'
          }, {
            value: '"previous"'
          }, {
            value: '"first"'
          }, {
            value: '"last"'
          }]
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/pagination/pagination_button_arrow.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ariaControls: {
        defaultValue: null,
        description: '',
        name: 'ariaControls',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/pagination/pagination_button_arrow.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'EuiButtonIconProps', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'AnchorHTMLAttributes']
  };
} catch (e) {}