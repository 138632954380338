/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { euiFlexGridStyles } from './flex_grid.styles';
export var DIRECTIONS = ['row', 'column'];
export var ALIGN_ITEMS = ['stretch', 'start', 'end', 'center', 'baseline'];
export var GUTTER_SIZES = ['none', 's', 'm', 'l', 'xl'];
export var EuiFlexGrid = ({
  children,
  className,
  style,
  gutterSize = 'l',
  direction = 'row',
  alignItems = 'stretch',
  responsive = true,
  columns = 1,
  component: Component = 'div',
  ...rest
}) => {
  const classes = classNames('euiFlexGrid', className);
  const styles = useEuiMemoizedStyles(euiFlexGridStyles);
  const cssStyles = [styles.euiFlexGrid, styles.gutterSizes[gutterSize], styles.direction[direction], styles.alignItems[alignItems], styles.columnCount[columns], responsive && styles.responsive];
  const columnDirectionStyles = useMemo(() => {
    if (direction === 'column') {
      const rowsToRender = Math.ceil(React.Children.count(children) / columns);
      return {
        gridTemplateRows: `repeat(${rowsToRender}, 1fr)`
      };
    }
  }, [direction, columns, children]);
  return <Component css={cssStyles} className={classes} style={columnDirectionStyles ? {
    ...style,
    ...columnDirectionStyles
  } : style} {...rest}>
      {children}
    </Component>;
};
EuiFlexGrid.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node,
  /**
     * Number of columns. Accepts `1-4`
     */
  columns: PropTypes.oneOf([1, 2, 3, 4]),
  // Leave this as inline so the props table correctly parses it
  /**
     * Flex layouts default to left-right then top-down (`row`).
     * Change this prop to `column` to create a top-down then left-right display.
     */
  direction: PropTypes.any,
  /**
     * Aligns grid items vertically
     */
  alignItems: PropTypes.any,
  /**
     * Space between flex items
     */
  gutterSize: PropTypes.any,
  /**
     * Will display each item at full-width on smaller screens
     */
  responsive: PropTypes.bool,
  /**
     * The tag to render
     * @default div
     */
  component: PropTypes.any
};
try {
  EuiFlexGrid.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/flex/flex_grid.tsx',
    description: '',
    displayName: 'EuiFlexGrid',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      columns: {
        defaultValue: {
          value: '1'
        },
        description: 'Number of columns. Accepts `1-4`',
        name: 'columns',
        parent: {
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '3 | 2 | 1 | 4',
          value: [{
            value: '3'
          }, {
            value: '2'
          }, {
            value: '1'
          }, {
            value: '4'
          }]
        }
      },
      direction: {
        defaultValue: {
          value: 'row'
        },
        description: 'Flex layouts default to left-right then top-down (`row`).\n' + 'Change this prop to `column` to create a top-down then left-right display.',
        name: 'direction',
        parent: {
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"row" | "column"',
          value: [{
            value: '"row"'
          }, {
            value: '"column"'
          }]
        }
      },
      alignItems: {
        defaultValue: {
          value: 'stretch'
        },
        description: 'Aligns grid items vertically',
        name: 'alignItems',
        parent: {
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"end" | "baseline" | "center" | "start" | "stretch"',
          value: [{
            value: '"end"'
          }, {
            value: '"baseline"'
          }, {
            value: '"center"'
          }, {
            value: '"start"'
          }, {
            value: '"stretch"'
          }]
        }
      },
      gutterSize: {
        defaultValue: {
          value: 'l'
        },
        description: 'Space between flex items',
        name: 'gutterSize',
        parent: {
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "s" | "m" | "l" | "xl"',
          value: [{
            value: '"none"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      responsive: {
        defaultValue: {
          value: 'true'
        },
        description: 'Will display each item at full-width on smaller screens',
        name: 'responsive',
        parent: {
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      component: {
        defaultValue: {
          value: 'div'
        },
        description: 'The tag to render',
        name: 'component',
        parent: {
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flex/flex_grid.tsx',
          name: 'EuiFlexGridProps'
        }],
        required: false,
        type: {
          name: 'ElementType<any, keyof IntrinsicElements>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiFlexGridProps']
  };
} catch (e) {}