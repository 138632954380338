/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keys, withEuiStylesMemoizer } from '../../../services';
import { Browser } from '../../../services/browser';
import { EuiI18n } from '../../i18n';
import { EuiFormControlLayout } from '../form_control_layout';
import { EuiValidatableControl } from '../validatable_control';
import { FormContext } from '../eui_form_context';
import { euiFieldSearchStyles } from './field_search.styles';
var isSearchSupported = false;
export class EuiFieldSearchClass extends Component {
  static contextType = FormContext;
  static defaultProps = {
    isLoading: false,
    incremental: false,
    compressed: false,
    isClearable: true
  };
  state = {
    value: this.props.value || String(this.props.defaultValue || '')
  };
  inputElement = null;
  cleanups = [];
  componentDidMount() {
    if (!this.inputElement) return;
    isSearchSupported = Browser.isEventSupported('search', this.inputElement);
    if (isSearchSupported) {
      const onSearch = event => {
        if (this.props.onSearch) {
          if (!event || !event.target || event.defaultPrevented) return;
          this.props.onSearch(event.target.value);
        }
      };
      this.inputElement.addEventListener('search', onSearch);
      this.cleanups.push(() => {
        if (!this.inputElement) return;
        this.inputElement.removeEventListener('search', onSearch);
      });
    }
    const onChange = event => {
      if (event.target && event.target.value !== this.state.value) {
        this.setState({
          value: event.target.value
        });
        if (this.props.onSearch) {
          this.props.onSearch(event.target.value);
        }
      }
    };
    this.inputElement.addEventListener('change', onChange);
  }
  onClear = () => {
    // clear the field's value

    // 1. React doesn't listen for `change` events, instead it maps `input` events to `change`
    // 2. React only fires the mapped `change` event if the element's value has changed
    // 3. An input's value is, in addition to other methods, tracked by intercepting element.value = '...'
    //
    // So we have to go below the element's value setter to avoid React intercepting it,
    // only then will React treat the value as different and fire its `change` event
    //
    // https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
    const nativeInputValue = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');
    const nativeInputValueSetter = nativeInputValue ? nativeInputValue.set : undefined;
    if (nativeInputValueSetter) {
      nativeInputValueSetter.call(this.inputElement, '');
    }

    // dispatch input event
    const event = new Event('input', {
      bubbles: true,
      cancelable: false
    });
    if (this.inputElement) {
      this.inputElement.dispatchEvent(event);
      // set focus on the search field
      this.inputElement.focus();
      this.inputElement.dispatchEvent(new Event('change'));
    }
    this.setState({
      value: ''
    });
    const {
      incremental,
      onSearch
    } = this.props;
    if (onSearch && incremental) {
      onSearch('');
    }
  };
  componentWillUnmount() {
    this.cleanups.forEach(cleanup => cleanup());
  }
  setRef = inputElement => {
    this.inputElement = inputElement;
    if (this.props.inputRef) {
      this.props.inputRef(inputElement);
    }
  };
  onKeyUp = (event, incremental, onSearch) => {
    this.setState({
      value: event.target.value
    });
    if (this.props.onKeyUp) {
      this.props.onKeyUp(event);
      if (event.defaultPrevented) {
        return;
      }
    }
    if (onSearch && (event.key !== keys.ENTER && incremental || event.key === keys.ENTER && !isSearchSupported)) {
      onSearch(event.target.value);
    }
  };
  render() {
    const {
      defaultFullWidth
    } = this.context;
    const {
      stylesMemoizer,
      className,
      id,
      name,
      placeholder,
      isInvalid,
      disabled,
      fullWidth = defaultFullWidth,
      isLoading,
      inputRef,
      incremental,
      compressed,
      onSearch,
      isClearable: _isClearable,
      append,
      prepend,
      ...rest
    } = this.props;
    let value = this.props.value;
    if (typeof this.props.value !== 'string') value = this.state.value;

    // Set actual value of isClearable if value exists as well
    const isClearable = Boolean(_isClearable && value && !rest.readOnly && !disabled);
    const classes = classNames('euiFieldSearch', {
      'euiFieldSearch-isLoading': isLoading,
      'euiFieldSearch-isClearable': isClearable,
      'euiFieldSearch-isInvalid': isInvalid
    }, className);
    const styles = stylesMemoizer(euiFieldSearchStyles);
    const cssStyles = [styles.euiFieldSearch, compressed ? styles.compressed : styles.uncompressed, fullWidth ? styles.fullWidth : styles.formWidth, (prepend || append) && styles.inGroup];
    return <EuiI18n token="euiFieldSearch.clearSearchButtonLabel" default="Clear search input">
        {clearSearchButtonLabel => <EuiFormControlLayout icon="search" fullWidth={fullWidth} isLoading={isLoading} isInvalid={isInvalid} isDisabled={disabled} clear={isClearable ? {
        onClick: this.onClear,
        'aria-label': clearSearchButtonLabel,
        'data-test-subj': 'clearSearchButton'
      } : undefined} compressed={compressed} append={append} prepend={prepend}>
            <EuiValidatableControl isInvalid={isInvalid}>
              <input type="search" id={id} name={name} placeholder={placeholder} className={classes} css={cssStyles} onKeyUp={e => this.onKeyUp(e, incremental, onSearch)} disabled={disabled} ref={this.setRef} {...rest} />
            </EuiValidatableControl>
          </EuiFormControlLayout>}
      </EuiI18n>;
  }
}
EuiFieldSearchClass.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  isInvalid: PropTypes.bool,
  /**
     * Expand to fill 100% of the parent.
     * Defaults to `fullWidth` prop of `<EuiForm>`.
     * @default false
     */
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  /**
     * Called when the user presses [Enter] OR on change if the incremental prop is `true`.
     * If you don't need the on[Enter] functionality, prefer using onChange
     */
  onSearch: PropTypes.func,
  /**
     * When `true` the search will be executed (that is, the `onSearch` will be called) as the
     * user types.
     */
  incremental: PropTypes.bool,
  /**
     * when `true` creates a shorter height input
     */
  compressed: PropTypes.bool,
  inputRef: PropTypes.func,
  /**
     * Shows a button that quickly clears any input
     */
  isClearable: PropTypes.bool,
  /**
     * Creates an input group with element(s) coming before input
     * `string` | `ReactElement` or an array of these
     */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  /**
     * Creates an input group with element(s) coming after input.
     * `string` | `ReactElement` or an array of these
     */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
export const EuiFieldSearch = withEuiStylesMemoizer(EuiFieldSearchClass);
try {
  EuiFieldSearchClass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/field_search/field_search.tsx',
    description: '',
    displayName: 'EuiFieldSearchClass',
    methods: [],
    props: {
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      placeholder: {
        defaultValue: null,
        description: '',
        name: 'placeholder',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onSearch: {
        defaultValue: null,
        description: 'Called when the user presses [Enter] OR on change if the incremental prop is `true`.\n' + "If you don't need the on[Enter] functionality, prefer using onChange",
        name: 'onSearch',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: '(value: string) => void'
        }
      },
      incremental: {
        defaultValue: {
          value: 'false'
        },
        description: 'When `true` the search will be executed (that is, the `onSearch` will be called) as the\n' + 'user types.',
        name: 'incremental',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: '(node: HTMLInputElement) => void'
        }
      },
      isClearable: {
        defaultValue: {
          value: 'true'
        },
        description: 'Shows a button that quickly clears any input',
        name: 'isClearable',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      stylesMemoizer: {
        defaultValue: null,
        description: '',
        name: 'stylesMemoizer',
        parent: {
          fileName: 'eui/src/services/theme/style_memoization.tsx',
          name: 'WithEuiStylesMemoizerProps'
        },
        declarations: [{
          fileName: 'eui/src/services/theme/style_memoization.tsx',
          name: 'WithEuiStylesMemoizerProps'
        }],
        required: true,
        type: {
          name: '<T extends (theme: UseEuiTheme<{}>) => StylesMap>(stylesGenerator: T) => ReturnType<T>'
        }
      }
    },
    extendedInterfaces: ['EuiFieldSearchProps', 'CommonProps', 'InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'WithEuiStylesMemoizerProps', 'Attributes']
  };
} catch (e) {}
try {
  EuiFieldSearch.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/field_search/field_search.tsx',
    description: '',
    displayName: 'EuiFieldSearch',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: '(node: HTMLInputElement) => void'
        }
      },
      placeholder: {
        defaultValue: null,
        description: '',
        name: 'placeholder',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      isClearable: {
        defaultValue: null,
        description: 'Shows a button that quickly clears any input',
        name: 'isClearable',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onSearch: {
        defaultValue: null,
        description: 'Called when the user presses [Enter] OR on change if the incremental prop is `true`.\n' + "If you don't need the on[Enter] functionality, prefer using onChange",
        name: 'onSearch',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: '(value: string) => void'
        }
      },
      incremental: {
        defaultValue: null,
        description: 'When `true` the search will be executed (that is, the `onSearch` will be called) as the\n' + 'user types.',
        name: 'incremental',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<unknown>'
        }
      }
    },
    extendedInterfaces: ['EuiFieldSearchProps', 'CommonProps', 'InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'WithEuiStylesMemoizerProps', 'Attributes']
  };
} catch (e) {}