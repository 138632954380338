/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { EuiModal } from './modal';
import { EuiModalFooter } from './modal_footer';
import { EuiModalHeader } from './modal_header';
import { EuiModalHeaderTitle } from './modal_header_title';
import { EuiModalBody } from './modal_body';
import { useEuiTheme } from '../../services';
import { euiModalStyles } from './modal.styles';
import { EuiButton, EuiButtonEmpty } from '../button';
import { EuiText } from '../text';
export var CONFIRM_BUTTON = 'confirm';
export var CANCEL_BUTTON = 'cancel';
export var EuiConfirmModal = ({
  children,
  title,
  titleProps,
  onCancel,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
  confirmButtonDisabled,
  className,
  buttonColor = 'primary',
  defaultFocusedButton,
  isLoading,
  ...rest
}) => {
  const cancelButtonRef = useRef(null);
  const confirmButtonRef = useRef(null);
  useEffect(() => {
    // We have to do this instead of using `autoFocus` because React's polyfill for auto-focusing
    // elements conflicts with the focus-trap logic we have on EuiModal.
    // Wait a beat for the focus-trap to complete, and then set focus to the right button. Check that
    // the buttons exist first, because it's possible the modal has been closed already.
    setTimeout(() => {
      if (defaultFocusedButton === CANCEL_BUTTON && cancelButtonRef.current) {
        cancelButtonRef.current.focus();
      } else if (defaultFocusedButton === CONFIRM_BUTTON && confirmButtonRef.current) {
        confirmButtonRef.current.focus();
      }
    });
  }, [defaultFocusedButton, cancelButtonRef, confirmButtonRef]);
  const classes = classnames('euiModal--confirmation', className);
  const euiTheme = useEuiTheme();
  const styles = euiModalStyles(euiTheme);
  const cssStyles = [styles.confirmation];
  let modalTitle;
  if (title) {
    modalTitle = <EuiModalHeader>
        <EuiModalHeaderTitle data-test-subj="confirmModalTitleText" {...titleProps}>
          {title}
        </EuiModalHeaderTitle>
      </EuiModalHeader>;
  }
  let message;
  if (typeof children === 'string' && children.length > 0) {
    message = <p>{children}</p>;
  } else {
    message = children;
  }
  return <EuiModal className={classes} css={cssStyles} onClose={onCancel} role="alertdialog" {...rest}>
      {modalTitle}

      {message && <EuiModalBody>
          <EuiText data-test-subj="confirmModalBodyText">{message}</EuiText>
        </EuiModalBody>}

      <EuiModalFooter>
        <EuiButtonEmpty data-test-subj="confirmModalCancelButton" onClick={onCancel} buttonRef={cancelButtonRef}>
          {cancelButtonText}
        </EuiButtonEmpty>

        <EuiButton data-test-subj="confirmModalConfirmButton" onClick={onConfirm} isLoading={isLoading} fill buttonRef={confirmButtonRef} color={buttonColor} isDisabled={confirmButtonDisabled}>
          {confirmButtonText}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>;
};
EuiConfirmModal.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node,
  title: PropTypes.node,
  titleProps: PropTypes.any,
  cancelButtonText: PropTypes.node,
  confirmButtonText: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  confirmButtonDisabled: PropTypes.bool,
  className: PropTypes.string,
  /**
     * Allows focusing either the confirm or cancel button on modal initialization.
     * Will take precedence over `initialFocus`, if `initialFocus` is passed.
     */
  defaultFocusedButton: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]),
  buttonColor: PropTypes.any,
  // For docs only, will get passed with ...rest
  /**
     * Sets the max-width of the modal.
     * Set to `true` to use the default (`euiBreakpoints 'm'`),
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  maxWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),
  /**
     * Passes `isLoading` prop to the confirm button
     */
  isLoading: PropTypes.bool
};
try {
  EuiConfirmModal.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/modal/confirm_modal.tsx',
    description: '',
    displayName: 'EuiConfirmModal',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      titleProps: {
        defaultValue: null,
        description: '',
        name: 'titleProps',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'Omit<EuiTitleProps, "children"> & HTMLAttributes<HTMLHeadingElement> & { component?: ElementType<any, keyof IntrinsicElements>; }'
        }
      },
      cancelButtonText: {
        defaultValue: null,
        description: '',
        name: 'cancelButtonText',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      confirmButtonText: {
        defaultValue: null,
        description: '',
        name: 'confirmButtonText',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      onCancel: {
        defaultValue: null,
        description: '',
        name: 'onCancel',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: true,
        type: {
          name: '(event?: any) => void'
        }
      },
      onConfirm: {
        defaultValue: null,
        description: '',
        name: 'onConfirm',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: '(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void'
        }
      },
      confirmButtonDisabled: {
        defaultValue: null,
        description: '',
        name: 'confirmButtonDisabled',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      defaultFocusedButton: {
        defaultValue: null,
        description: 'Allows focusing either the confirm or cancel button on modal initialization.\n' + 'Will take precedence over `initialFocus`, if `initialFocus` is passed.',
        name: 'defaultFocusedButton',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"confirm" | "cancel"',
          value: [{
            value: '"confirm"'
          }, {
            value: '"cancel"'
          }]
        }
      },
      buttonColor: {
        defaultValue: {
          value: 'primary'
        },
        description: '',
        name: 'buttonColor',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "accent" | "primary" | "success" | "warning" | "danger"',
          value: [{
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }]
        }
      },
      maxWidth: {
        defaultValue: null,
        description: 'Sets the max-width of the modal.\n' + "Set to `true` to use the default (`euiBreakpoints 'm'`),\n" + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'maxWidth',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'string | number | boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Passes `isLoading` prop to the confirm button',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/confirm_modal.tsx',
          name: 'EuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      role: {
        defaultValue: null,
        description: 'Identifies a modal dialog to screen readers. Modal dialogs that confirm destructive actions\n' + `or need a user's attention should use "alertdialog".`,
        name: 'role',
        parent: {
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"alertdialog" | "dialog"',
          value: [{
            value: '"alertdialog"'
          }, {
            value: '"dialog"'
          }]
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      initialFocus: {
        defaultValue: null,
        description: 'Specifies what element should initially have focus.\n' + 'Can be a DOM node, or a selector string (which will be passed to document.querySelector() to find the DOM node), or a function that returns a DOM node.',
        name: 'initialFocus',
        parent: {
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/modal/modal.tsx',
          name: 'EuiModalProps'
        }],
        required: false,
        type: {
          name: 'string | HTMLElement | (() => HTMLElement)'
        }
      }
    },
    extendedInterfaces: ['EuiConfirmModalProps', 'HTMLAttributes', 'EuiModalProps', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}