/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { euiFormLabelStyles } from './form_label.styles';
export var EuiFormLabel = ({
  type = 'label',
  isFocused,
  isInvalid,
  isDisabled,
  children,
  className,
  ...rest
}) => {
  const styles = useEuiMemoizedStyles(euiFormLabelStyles);
  const cssStyles = [styles.euiFormLabel, !isDisabled && styles.notDisabled, isInvalid && styles.invalid, isFocused && styles.focused];
  const classes = classNames('euiFormLabel', className, {
    'euiFormLabel-isFocused': isFocused,
    'euiFormLabel-isInvalid': isInvalid,
    'euiFormLabel-isDisabled': isDisabled
  });
  if (type === 'legend') {
    return <legend css={cssStyles} className={classes} {...rest}>
        {children}
      </legend>;
  } else {
    return <label css={cssStyles} className={classes} {...rest}>
        {children}
      </label>;
  }
};
EuiFormLabel.propTypes = {
  /**
     * Default type is a `label` but can be changed to a `legend`
     * if using inside a `fieldset`.
     */
  /**
     * Default type is a `label` but can be changed to a `legend`
     * if using inside a `fieldset`.
     */
  type: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.oneOf(["label"]), PropTypes.oneOf(["label", "legend"])]), PropTypes.oneOfType([PropTypes.oneOf(["legend"]).isRequired, PropTypes.oneOf(["label", "legend"])])]),
  isFocused: PropTypes.bool,
  isInvalid: PropTypes.bool,
  /**
     * Changes `cursor` to `default`.
     */
  /**
     * Changes `cursor` to `default`.
     */
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiFormLabel.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/form_label/form_label.tsx',
    description: '',
    displayName: 'EuiFormLabel',
    methods: [],
    props: {
      type: {
        defaultValue: {
          value: 'label'
        },
        description: 'Default type is a `label` but can be changed to a `legend`\n' + 'if using inside a `fieldset`.',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_label/form_label.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/form/form_label/form_label.tsx',
          name: 'EuiFormLabelCommonProps'
        }, {
          fileName: 'eui/src/components/form/form_label/form_label.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/form/form_label/form_label.tsx',
          name: 'EuiFormLabelCommonProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"legend" | "label"',
          value: [{
            value: '"legend"'
          }, {
            value: '"label"'
          }]
        }
      },
      isFocused: {
        defaultValue: null,
        description: '',
        name: 'isFocused',
        parent: {
          fileName: 'eui/src/components/form/form_label/form_label.tsx',
          name: 'EuiFormLabelCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/form_label/form_label.tsx',
          name: 'EuiFormLabelCommonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/form_label/form_label.tsx',
          name: 'EuiFormLabelCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/form_label/form_label.tsx',
          name: 'EuiFormLabelCommonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: 'Changes `cursor` to `default`.',
        name: 'isDisabled',
        parent: {
          fileName: 'eui/src/components/form/form_label/form_label.tsx',
          name: 'EuiFormLabelCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/form_label/form_label.tsx',
          name: 'EuiFormLabelCommonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['LabelHTMLAttributes', 'EuiFormLabelCommonProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}