/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import classNames from 'classnames';
import { useEuiTheme } from '../../../services';
import { euiAccordionButtonStyles } from './accordion_button.styles';
export var EuiAccordionButton = ({
  buttonElement: ButtonElement,
  buttonProps: _buttonProps,
  buttonClassName,
  buttonContentClassName,
  isDisabled,
  arrowDisplay,
  children,
  ...rest
}) => {
  const {
    paddingSize,
    ...buttonProps
  } = _buttonProps || {};
  const classes = classNames('euiAccordion__button', buttonClassName, buttonProps.className);
  const buttonContentClasses = classNames('euiAccordion__buttonContent', buttonContentClassName);
  const euiTheme = useEuiTheme();
  const styles = euiAccordionButtonStyles(euiTheme);
  const cssStyles = [styles.euiAccordion__button, isDisabled && styles.disabled, ...(paddingSize ? [styles[paddingSize], arrowDisplay === 'left' && styles.arrowLeft, arrowDisplay === 'right' && styles.arrowRight] : []), buttonProps.css];
  const elementIsButton = ButtonElement === 'button';
  return <ButtonElement {...buttonProps} {...rest} className={classes} css={cssStyles} type={elementIsButton ? 'button' : undefined} disabled={elementIsButton ? isDisabled : undefined}>
      <span className={buttonContentClasses}>{children}</span>
    </ButtonElement>;
};
try {
  EuiAccordionButton.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/accordion/accordion_trigger/accordion_button.tsx',
    description: '',
    displayName: 'EuiAccordionButton',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      buttonElement: {
        defaultValue: null,
        description: 'Applied to the main button receiving the `onToggle` event.\n' + 'Anything other than the default `button` does not support removing the arrow display (for accessibility of focus).',
        name: 'buttonElement',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "div" | "legend"',
          value: [{
            value: '"button"'
          }, {
            value: '"div"'
          }, {
            value: '"legend"'
          }]
        }
      },
      isDisabled: {
        defaultValue: null,
        description: 'Disable the open/close interaction and visually subdues the trigger',
        name: 'isDisabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      buttonClassName: {
        defaultValue: null,
        description: 'Class that will apply to the trigger for the accordion.',
        name: 'buttonClassName',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      buttonProps: {
        defaultValue: null,
        description: 'Apply more props to the triggering button.\n' + '\n' + 'Includes optional `paddingSize` prop which allows sizes of `s`, `m`, or `l`.\n' + 'Note: Padding will not be present on the side closest to the accordion arrow.',
        name: 'buttonProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLElement> & { paddingSize?: "s" | "m" | "l"; }'
        }
      },
      buttonContentClassName: {
        defaultValue: null,
        description: 'Class that will apply to the trigger content for the accordion.',
        name: 'buttonContentClassName',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      arrowDisplay: {
        defaultValue: null,
        description: "Placement of the arrow indicator, or 'none' to hide it.",
        name: 'arrowDisplay',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "left" | "right"',
          value: [{
            value: '"none"'
          }, {
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}