/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../services';
import { EuiTitle } from '../title';
import { EuiScreenReaderOnly } from '../accessibility';
export var EuiSideNavHeading = ({
  children,
  id,
  className,
  element: HeadingElement = 'h2',
  screenReaderOnly = false,
  // EuiTitle specific props
  size = 'xs',
  textTransform,
  ...rest
}) => {
  const classes = classNames('euiSideNav__heading', className);
  const {
    euiTheme
  } = useEuiTheme();
  return screenReaderOnly ? <EuiScreenReaderOnly>
      <HeadingElement id={id} className={classes} {...rest}>
        {children}
      </HeadingElement>
    </EuiScreenReaderOnly> : <EuiTitle size={size} textTransform={textTransform}>
      <HeadingElement id={id} className={classes} css={{
      marginBlockEnd: euiTheme.size.l
    }} {...rest}>
        {children}
      </HeadingElement>
    </EuiTitle>;
};
EuiSideNavHeading.propTypes = {
  children: PropTypes.node.isRequired,
  /**
     * The actual HTML heading element to wrap the `heading`.
     * Default is `h2`
     */
  element: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "span"]),
  /**
     * For best accessibility, `<nav>` elements should have a nested heading. But you can hide this element if it's redundent from something else (except on mobile).
     */
  screenReaderOnly: PropTypes.bool
};
try {
  EuiSideNavHeading.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/side_nav/_side_nav_heading.tsx',
    description: '',
    displayName: 'EuiSideNavHeading',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: {
          value: 'xs'
        },
        description: '',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "l" | "xxxs" | "xxs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xxxs"'
          }, {
            value: '"xxs"'
          }]
        }
      },
      textTransform: {
        defaultValue: null,
        description: '',
        name: 'textTransform',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '"uppercase"'
        }
      },
      element: {
        defaultValue: null,
        description: 'The actual HTML heading element to wrap the `heading`.\nDefault is `h2`',
        name: 'element',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/side_nav/_side_nav_heading.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"span" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6"',
          value: [{
            value: '"span"'
          }, {
            value: '"h1"'
          }, {
            value: '"h2"'
          }, {
            value: '"h3"'
          }, {
            value: '"h4"'
          }, {
            value: '"h5"'
          }, {
            value: '"h6"'
          }]
        }
      },
      screenReaderOnly: {
        defaultValue: {
          value: 'false'
        },
        description: "For best accessibility, `<nav>` elements should have a nested heading. But you can hide this element if it's redundent from something else (except on mobile).",
        name: 'screenReaderOnly',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/side_nav/_side_nav_heading.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps']
  };
} catch (e) {}