/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { EuiSkeletonLoading } from './skeleton_loading';
import { euiSkeletonTextStyles } from './skeleton_text.styles';
export var LINES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export var EuiSkeletonText = ({
  isLoading = true,
  lines = 3,
  size = 'm',
  className,
  contentAriaLabel,
  announceLoadingStatus,
  announceLoadedStatus,
  ariaLiveProps,
  ariaWrapperProps,
  children,
  ...rest
}) => {
  const styles = useEuiMemoizedStyles(euiSkeletonTextStyles);
  const cssStyles = useMemo(() => [styles.euiSkeletonText, styles[size]], [styles, size]);
  const lineElements = useMemo(() => {
    const lineElements = [];
    for (let i = 0; i < lines; i++) {
      lineElements.push(<span key={i} css={cssStyles} />);
    }
    return lineElements;
  }, [lines, cssStyles]);
  return <EuiSkeletonLoading isLoading={isLoading} loadingContent={<span className={classNames('euiSkeletonText', className)} {...rest}>
          {lineElements}
        </span>} loadedContent={children || ''} contentAriaLabel={contentAriaLabel} announceLoadingStatus={announceLoadingStatus} announceLoadedStatus={announceLoadedStatus} ariaLiveProps={ariaLiveProps} {...ariaWrapperProps} />;
};
EuiSkeletonText.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * When true, shows the loading skeleton component.
     * When false, shows any `children` and announces to screen readers that your content has loaded.
     */
  isLoading: PropTypes.bool,
  /**
     * Label your loading sections to provide more helpful context to screen readers.
     * For example, pass "API keys" to have screen readers read "Loading API keys" and "Loaded API keys".
     */
  contentAriaLabel: PropTypes.string,
  /**
     * Makes a live screen reader announcement when `isLoading` is true
     * @default false
     */
  announceLoadingStatus: PropTypes.bool,
  /**
     * Makes a live screen reader announcement when `isLoading` is false
     * @default true
     */
  announceLoadedStatus: PropTypes.bool,
  /**
     * Optional props to pass to the `aria-live` region that announces the loading status to screen readers.
     * Accepts any `EuiScreenReaderLive` props.
     */
  ariaLiveProps: PropTypes.any,
  /**
     * Optional props to pass to the `aria-busy` wrapper around the skeleton content
     */
  ariaWrapperProps: PropTypes.any,
  /**
       * Number of lines to display (between 1 to 10)
       */
  lines: PropTypes.any,
  /**
       * EuiText size to render
       */
  size: PropTypes.any
};
try {
  EuiSkeletonText.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/skeleton/skeleton_text.tsx',
    description: '',
    displayName: 'EuiSkeletonText',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'true'
        },
        description: 'When true, shows the loading skeleton component.\n' + 'When false, shows any `children` and announces to screen readers that your content has loaded.',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/skeleton/skeleton_loading.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      contentAriaLabel: {
        defaultValue: null,
        description: 'Label your loading sections to provide more helpful context to screen readers.\n' + 'For example, pass "API keys" to have screen readers read "Loading API keys" and "Loaded API keys".',
        name: 'contentAriaLabel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/skeleton/skeleton_loading.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      announceLoadingStatus: {
        defaultValue: {
          value: 'false'
        },
        description: 'Makes a live screen reader announcement when `isLoading` is true',
        name: 'announceLoadingStatus',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/skeleton/skeleton_loading.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      announceLoadedStatus: {
        defaultValue: {
          value: 'true'
        },
        description: 'Makes a live screen reader announcement when `isLoading` is false',
        name: 'announceLoadedStatus',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/skeleton/skeleton_loading.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ariaLiveProps: {
        defaultValue: null,
        description: 'Optional props to pass to the `aria-live` region that announces the loading status to screen readers.\n' + 'Accepts any `EuiScreenReaderLive` props.',
        name: 'ariaLiveProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/skeleton/skeleton_loading.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<EuiScreenReaderLiveProps>'
        }
      },
      ariaWrapperProps: {
        defaultValue: null,
        description: 'Optional props to pass to the `aria-busy` wrapper around the skeleton content',
        name: 'ariaWrapperProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/skeleton/skeleton_loading.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLDivElement>'
        }
      },
      lines: {
        defaultValue: {
          value: '3'
        },
        description: 'Number of lines to display (between 1 to 10)',
        name: 'lines',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/skeleton/skeleton_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '6 | 3 | 2 | 1 | 10 | 4 | 7 | 8 | 5 | 9',
          value: [{
            value: '6'
          }, {
            value: '3'
          }, {
            value: '2'
          }, {
            value: '1'
          }, {
            value: '10'
          }, {
            value: '4'
          }, {
            value: '7'
          }, {
            value: '8'
          }, {
            value: '5'
          }, {
            value: '9'
          }]
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: 'EuiText size to render',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/skeleton/skeleton_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "relative"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"relative"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}