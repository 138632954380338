import React from 'react';
import PropTypes from "prop-types";
// For popover `panelProps` documentation, we shouldn't use `EuiPopoverPanelProps` directly
// as EuiPopover opinionatedly overrides some props that `EuiPopoverPanel` itself allows
export var EuiPopoverPanelProps = () => <div />;
EuiPopoverPanelProps.propTypes = {
  element: PropTypes.oneOf(["div"]),
  /**
     * Padding for all four sides
     */
  paddingSize: PropTypes.any,
  /**
     * Corner border radius
     */
  borderRadius: PropTypes.any,
  /**
     * When true the panel will grow in height to match `EuiFlexItem`
     */
  grow: PropTypes.bool,
  panelRef: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiPopoverPanelProps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/popover/props.tsx',
    description: '',
    displayName: 'EuiPopoverPanelProps',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      element: {
        defaultValue: null,
        description: '',
        name: 'element',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelDivlike'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelDivlike'
        }],
        required: false,
        type: {
          name: '"div"'
        }
      },
      paddingSize: {
        defaultValue: null,
        description: 'Padding for all four sides',
        name: 'paddingSize',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "s" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"none"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      borderRadius: {
        defaultValue: null,
        description: 'Corner border radius',
        name: 'borderRadius',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "m"',
          value: [{
            value: '"none"'
          }, {
            value: '"m"'
          }]
        }
      },
      grow: {
        defaultValue: null,
        description: 'When true the panel will grow in height to match `EuiFlexItem`',
        name: 'grow',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', '_EuiPanelDivlike', '_EuiPanelProps']
  };
} catch (e) {}