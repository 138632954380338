/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { setStyleForRestrictedPageWidth } from '../_restrict_width';
import { useEuiTheme } from '../../../services';
import { euiPageSectionContentStyles, euiPageSectionStyles } from './page_section.styles';
import { useEuiPaddingCSS, useEuiBackgroundColorCSS } from '../../../global_styling';
export var EuiPageSection = ({
  children,
  alignment = 'top',
  restrictWidth = false,
  bottomBorder,
  paddingSize = 'l',
  color = 'transparent',
  grow = false,
  contentProps,
  component: Component = 'section',
  ...rest
}) => {
  // Set max-width as a style prop
  const widthStyles = setStyleForRestrictedPageWidth(restrictWidth, contentProps?.style);
  const useTheme = useEuiTheme();
  const styles = euiPageSectionStyles(useTheme);
  const inlinePadding = useEuiPaddingCSS('horizontal');
  const blockPadding = useEuiPaddingCSS('vertical');
  const colors = useEuiBackgroundColorCSS();
  const cssStyles = [styles.euiPageSection, grow && styles.grow, inlinePadding[paddingSize], bottomBorder === 'extended' && styles.border, alignment && styles[alignment], colors[color]];
  const contentStyles = euiPageSectionContentStyles();
  const cssContentStyles = [contentStyles.euiPageSection__content, blockPadding[paddingSize], bottomBorder === true && styles.border, alignment.toLowerCase().includes('center') && contentStyles.center, restrictWidth && contentStyles.restrictWidth, contentProps?.css];
  return <Component css={cssStyles} {...rest}>
      <div {...contentProps} css={cssContentStyles} style={widthStyles}>
        {children}
      </div>
    </Component>;
};
EuiPageSection.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Sets the max-width of the page,
     * set to `true` to use the default size of `1200px`,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),
  /**
     * Adds a bottom border to separate it from the content after;
     * Passing `extended` will ensure the border touches the sides of the parent container.
     */
  bottomBorder: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["extended"])]),
  /**
       * Background color of the section;
       * Usually a lightened form of the brand colors
       */
  color: PropTypes.any,
  /**
       * Padding for all four sides
       */
  paddingSize: PropTypes.any,
  /**
       * Horizontal and/or vertical alignment of the section contents
       */
  alignment: PropTypes.any,
  /**
       * When true the panel will grow in height to fill container if parent is a flex group
       */
  grow: PropTypes.bool,
  /**
       * Passed down to the div wrapper of the section contents
       */
  contentProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  /**
       * Sets which HTML element to render.
       */
  component: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.elementType.isRequired])
};
try {
  EuiPageSection.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/page/page_section/page_section.tsx',
    description: '',
    displayName: 'EuiPageSection',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      restrictWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Sets the max-width of the page,\n' + 'set to `true` to use the default size of `1200px`,\n' + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'restrictWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/_restrict_width.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number | boolean'
        }
      },
      bottomBorder: {
        defaultValue: null,
        description: 'Adds a bottom border to separate it from the content after;\n' + 'Passing `extended` will ensure the border touches the sides of the parent container.',
        name: 'bottomBorder',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/_bottom_border.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | "extended"'
        }
      },
      color: {
        defaultValue: {
          value: 'transparent'
        },
        description: 'Background color of the section;\n' + 'Usually a lightened form of the brand colors',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"transparent" | "accent" | "primary" | "success" | "warning" | "danger" | "subdued" | "plain"',
          value: [{
            value: '"transparent"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"plain"'
          }]
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'l'
        },
        description: 'Padding for all four sides',
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "s" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"none"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      alignment: {
        defaultValue: {
          value: 'top'
        },
        description: 'Horizontal and/or vertical alignment of the section contents',
        name: 'alignment',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"top" | "center" | "horizontalCenter"',
          value: [{
            value: '"top"'
          }, {
            value: '"center"'
          }, {
            value: '"horizontalCenter"'
          }]
        }
      },
      grow: {
        defaultValue: {
          value: 'false'
        },
        description: 'When true the panel will grow in height to fill container if parent is a flex group',
        name: 'grow',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      contentProps: {
        defaultValue: null,
        description: 'Passed down to the div wrapper of the section contents',
        name: 'contentProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLDivElement>'
        }
      },
      component: {
        defaultValue: null,
        description: 'Sets which HTML element to render.',
        name: 'component',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ComponentType<{}> | keyof IntrinsicElements'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}