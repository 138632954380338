/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { useEuiBackgroundColorCSS, useEuiPaddingCSS, BACKGROUND_COLORS, PADDING_SIZES } from '../../global_styling';
import { keysOf } from '../common';
import { euiPanelStyles } from './panel.styles';
export var SIZES = PADDING_SIZES;

// Exported padding sizes and class names necessary for EuiPopover and EuiCard.
// Which currently will only maintain support for the original values until conversion.
var paddingSizeToClassNameMap = {
  none: null,
  s: 'paddingSmall',
  m: 'paddingMedium',
  l: 'paddingLarge'
};
var _SIZES = keysOf(paddingSizeToClassNameMap);
export var BORDER_RADII = ['none', 'm'];
export var COLORS = BACKGROUND_COLORS;
export var EuiPanel = ({
  children,
  className,
  paddingSize = 'm',
  borderRadius = 'm',
  color = 'plain',
  hasShadow = true,
  hasBorder = false,
  grow = true,
  panelRef,
  element,
  ...rest
}) => {
  // Shadows are only allowed when there's a white background (plain)
  const canHaveShadow = !hasBorder && color === 'plain';
  const canHaveBorder = color === 'plain' || color === 'transparent';
  const styles = useEuiMemoizedStyles(euiPanelStyles);
  const cssStyles = [styles.euiPanel, grow && styles.grow, styles.radius[borderRadius], useEuiPaddingCSS()[paddingSize], useEuiBackgroundColorCSS()[color], canHaveShadow && hasShadow === true && styles.hasShadow, canHaveBorder && hasBorder === true && styles.hasBorder, rest.onClick && styles.isClickable];
  const classes = classNames('euiPanel', `euiPanel--${color}`, {
    [`euiPanel--${paddingSizeToClassNameMap[paddingSize]}`]: paddingSizeToClassNameMap[paddingSize]
  }, className);
  if (rest.onClick && element !== 'div') {
    return <button ref={panelRef} className={classes} css={cssStyles} {...rest}>
        {children}
      </button>;
  }
  return <div ref={panelRef} className={classes} css={cssStyles} {...rest}>
      {children}
    </div>;
};
EuiPanel.propTypes = {
  element: PropTypes.oneOfType([PropTypes.oneOf(["button"]), PropTypes.oneOf(["div"])]),
  /**
     * Adds a medium shadow to the panel;
     * Only works when `color="plain"`
     */
  /**
     * Adds a medium shadow to the panel;
     * Only works when `color="plain"`
     */
  hasShadow: PropTypes.bool,
  /**
     * Adds a slight 1px border on all edges.
     * Only works when `color="plain | transparent"`
     */
  /**
     * Adds a slight 1px border on all edges.
     * Only works when `color="plain | transparent"`
     */
  hasBorder: PropTypes.bool,
  /**
     * Padding for all four sides
     */
  /**
     * Padding for all four sides
     */
  paddingSize: PropTypes.any,
  /**
     * Corner border radius
     */
  /**
     * Corner border radius
     */
  borderRadius: PropTypes.any,
  /**
     * When true the panel will grow in height to match `EuiFlexItem`
     */
  /**
     * When true the panel will grow in height to match `EuiFlexItem`
     */
  grow: PropTypes.bool,
  panelRef: PropTypes.any,
  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */
  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */
  color: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiPanel.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/panel/panel.tsx',
    description: '',
    displayName: 'EuiPanel',
    methods: [],
    props: {
      element: {
        defaultValue: null,
        description: '',
        name: 'element',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelDivlike'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelDivlike'
        }, {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelButtonlike'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "div"',
          value: [{
            value: '"button"'
          }, {
            value: '"div"'
          }]
        }
      },
      hasShadow: {
        defaultValue: {
          value: 'true'
        },
        description: 'Adds a medium shadow to the panel;\nOnly works when `color="plain"`',
        name: 'hasShadow',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasBorder: {
        defaultValue: {
          value: 'false'
        },
        description: 'Adds a slight 1px border on all edges.\n' + 'Only works when `color="plain | transparent"`',
        name: 'hasBorder',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'm'
        },
        description: 'Padding for all four sides',
        name: 'paddingSize',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "s" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"none"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      borderRadius: {
        defaultValue: {
          value: 'm'
        },
        description: 'Corner border radius',
        name: 'borderRadius',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "m"',
          value: [{
            value: '"none"'
          }, {
            value: '"m"'
          }]
        }
      },
      grow: {
        defaultValue: {
          value: 'true'
        },
        description: 'When true the panel will grow in height to match `EuiFlexItem`',
        name: 'grow',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      color: {
        defaultValue: {
          value: 'plain'
        },
        description: 'Background color of the panel;\n' + 'Usually a lightened form of the brand colors',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"transparent" | "accent" | "primary" | "success" | "warning" | "danger" | "subdued" | "plain"',
          value: [{
            value: '"transparent"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"plain"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['ButtonHTMLAttributes', '_EuiPanelDivlike', '_EuiPanelProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}