/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../services';
import { useEuiI18n } from '../i18n';
import { useInnerText } from '../inner_text';
import { EuiNotificationBadge } from '../badge';
import { EuiButtonEmpty } from '../button/button_empty';
import { euiFilterButtonStyles, euiFilterButtonChildStyles } from './filter_button.styles';
export var EuiFilterButton = ({
  children,
  className,
  iconType,
  iconSide = 'right',
  color = 'text',
  badgeColor = 'accent',
  hasActiveFilters,
  numFilters,
  numActiveFilters,
  isDisabled,
  isSelected,
  type = 'button',
  grow = true,
  withNext,
  textProps,
  contentProps,
  ...rest
}) => {
  const numFiltersDefined = numFilters != null; // != instead of !== to allow for null and undefined
  const numActiveFiltersDefined = numActiveFilters != null && numActiveFilters > 0;
  const euiTheme = useEuiTheme();
  const styles = euiFilterButtonStyles(euiTheme);
  const cssStyles = [styles.euiFilterButton, withNext && styles.withNext, !grow && styles.noGrow, hasActiveFilters && styles.hasActiveFilters, numFiltersDefined && styles.hasNotification];
  const {
    content: contentStyles,
    text: textStyles,
    notification: notificationStyles
  } = euiFilterButtonChildStyles(euiTheme);
  const classes = classNames('euiFilterButton', {
    'euiFilterButton-isSelected': isSelected,
    'euiFilterButton-hasActiveFilters': hasActiveFilters,
    'euiFilterButton-hasNotification': numFiltersDefined
  }, className);

  /**
   * Badge
   */
  const showBadge = numFiltersDefined || numActiveFiltersDefined;
  const badgeCount = numActiveFilters || numFilters;
  const activeBadgeLabel = useEuiI18n('euiFilterButton.filterBadgeActiveAriaLabel', '{count} active filters', {
    count: badgeCount
  });
  const availableBadgeLabel = useEuiI18n('euiFilterButton.filterBadgeAvailableAriaLabel', '{count} available filters', {
    count: badgeCount
  });
  const badgeContent = showBadge && <EuiNotificationBadge className="euiFilterButton__notification" css={[notificationStyles.euiFilterButton__notification, isDisabled && notificationStyles.disabled]} aria-label={hasActiveFilters ? activeBadgeLabel : availableBadgeLabel} color={isDisabled || !hasActiveFilters ? 'subdued' : badgeColor} role="marquee" // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/marquee_role
  >
      {badgeCount}
    </EuiNotificationBadge>;

  /**
   * Text
   */
  const buttonTextClassNames = classNames('euiFilterButton__text', {
    'euiFilterButton__text-hasNotification': showBadge
  }, textProps && textProps.className);
  const textCssStyles = [textStyles.euiFilterButton__text, showBadge && textStyles.hasNotification, textProps && textProps.css];
  const [ref, innerText] = useInnerText();
  const dataText = children && typeof children === 'string' ? children : innerText;
  const textContent = <span ref={ref} data-text={dataText} title={dataText} {...textProps} className={buttonTextClassNames} css={textCssStyles}>
      {children}
    </span>;
  return <EuiButtonEmpty className={classes} css={cssStyles} color={color} isDisabled={isDisabled} iconSide={iconSide} iconType={iconType} type={type} textProps={false} contentProps={{
    ...contentProps,
    css: [contentStyles.euiFilterButton__content, iconType && contentStyles.hasIcon, contentProps?.css]
  }} {...rest}>
      {textContent}
      {badgeContent}
    </EuiButtonEmpty>;
};
EuiFilterButton.propTypes = {
  /**
     * Bolds the button if true
     */
  hasActiveFilters: PropTypes.bool,
  /**
     * Pass the total number of filters available and it will
     * add a subdued notification badge showing the number
     */
  numFilters: PropTypes.number,
  /**
     * Pass the number of selected filters and it will
     * add a bright notification badge showing the number
     */
  numActiveFilters: PropTypes.number,
  /**
     * Applies a visual state to the button useful when using with a popover.
     */
  isSelected: PropTypes.bool,
  /**
     * Should the button grow to fill its container, best used for dropdown buttons
     */
  grow: PropTypes.bool,
  /**
     * Remove border after button, good for opposite filters
     */
  withNext: PropTypes.bool,
  /**
     * Change color of the counter badge
     */
  badgeColor: PropTypes.any
};
try {
  EuiFilterButton.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/filter_group/filter_button.tsx',
    description: '',
    displayName: 'EuiFilterButton',
    methods: [],
    props: {
      hasActiveFilters: {
        defaultValue: null,
        description: 'Bolds the button if true',
        name: 'hasActiveFilters',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      numFilters: {
        defaultValue: null,
        description: 'Pass the total number of filters available and it will\n' + 'add a subdued notification badge showing the number',
        name: 'numFilters',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      numActiveFilters: {
        defaultValue: null,
        description: 'Pass the number of selected filters and it will\n' + 'add a bright notification badge showing the number',
        name: 'numActiveFilters',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      isSelected: {
        defaultValue: null,
        description: 'Applies a visual state to the button useful when using with a popover.\n' + 'Applies the boolean state as the `aria-pressed` property to create a toggle button.\n' + '*Only use when the readable text does not change between states.*',
        name: 'isSelected',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_button.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/src/components/filter_group/filter_button.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      grow: {
        defaultValue: {
          value: 'true'
        },
        description: 'Should the button grow to fill its container, best used for dropdown buttons',
        name: 'grow',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      withNext: {
        defaultValue: null,
        description: 'Remove border after button, good for opposite filters',
        name: 'withNext',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      badgeColor: {
        defaultValue: {
          value: 'accent'
        },
        description: 'Change color of the counter badge',
        name: 'badgeColor',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"accent" | "success" | "subdued"',
          value: [{
            value: '"accent"'
          }, {
            value: '"success"'
          }, {
            value: '"subdued"'
          }]
        }
      },
      color: {
        defaultValue: {
          value: 'text'
        },
        description: 'Any of the named color palette options.',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "accent" | "primary" | "success" | "warning" | "danger"',
          value: [{
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      rel: {
        defaultValue: null,
        description: '',
        name: 'rel',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }],
        required: false,
        type: {
          name: 'MouseEventHandler<HTMLAnchorElement> | MouseEventHandler<HTMLButtonElement>'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Force disables the button and changes the icon to a loading spinner',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      contentProps: {
        defaultValue: null,
        description: "Object of props passed to the `<span>` wrapping the button's content",
        name: 'contentProps',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLSpanElement>'
        }
      },
      type: {
        defaultValue: {
          value: 'button'
        },
        description: '',
        name: 'type',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'ButtonHTMLAttributes'
        }, {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AnchorHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "submit" | "reset"',
          value: [{
            value: '"button"'
          }, {
            value: '"submit"'
          }, {
            value: '"reset"'
          }]
        }
      },
      iconType: {
        defaultValue: null,
        description: 'Any `type` accepted by EuiIcon',
        name: 'iconType',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }, {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      iconSide: {
        defaultValue: {
          value: 'right'
        },
        description: 'Can only be one side `left` or `right`',
        name: 'iconSide',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }, {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ButtonContentIconSide',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      textProps: {
        defaultValue: null,
        description: "Object of props passed to the `<span>` wrapping the content's text/children only (not icon)\n" + '\n' + 'This span wrapper can be removed by passing `textProps={false}`.',
        name: 'textProps',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }, {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: "false | (HTMLAttributes<HTMLSpanElement> & CommonProps & { ref?: Ref<HTMLSpanElement>; 'data-text'?: string; })"
        }
      },
      iconSize: {
        defaultValue: null,
        description: '',
        name: 'iconSize',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }, {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '`disabled` is also allowed',
        name: 'isDisabled',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      href: {
        defaultValue: null,
        description: '',
        name: 'href',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AnchorHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      target: {
        defaultValue: null,
        description: '',
        name: 'target',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AnchorHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string | (string & {})'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }, {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLAnchorElement | HTMLButtonElement>'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'CommonEuiButtonEmptyProps', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'ButtonHTMLAttributes', 'EuiButtonDisplayContentProps', 'AnchorHTMLAttributes']
  };
} catch (e) {}