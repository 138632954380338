function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
import PropTypes from "prop-types";
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useState, useCallback, useMemo, useRef } from 'react';
import { keys, useEuiMemoizedStyles } from '../../services';
import { useEuiI18n } from '../i18n';
import { EuiButtonIcon } from '../button';
import { EuiFocusTrap } from '../focus_trap';
import { EuiOverlayMask } from '../overlay_mask';
import { euiCodeBlockStyles } from './code_block.styles';
import { EuiDelayRender } from '../delay_render';

/**
 * Hook that returns fullscreen-related state/logic/utils
 */
export var useFullScreen = function useFullScreen(_ref) {
  var overflowHeight = _ref.overflowHeight;
  var toggleButtonRef = useRef(null);
  var showFullScreenButton = !!overflowHeight;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isFullScreen = _useState2[0],
    setIsFullScreen = _useState2[1];
  var returnFocus = function returnFocus() {
    // uses timeout to ensure focus is placed after potential other updates happen
    setTimeout(function () {
      var _toggleButtonRef$curr;
      (_toggleButtonRef$curr = toggleButtonRef.current) === null || _toggleButtonRef$curr === void 0 || _toggleButtonRef$curr.focus();
    });
  };
  var toggleFullScreen = useCallback(function () {
    setIsFullScreen(function (isFullScreen) {
      return !isFullScreen;
    });
    if (isFullScreen) {
      returnFocus();
    }
  }, [isFullScreen]);
  var onKeyDown = useCallback(function (event) {
    if (event.key === keys.ESCAPE) {
      // We need to make sure annotation Escape keypresses don't also cause fullscreen mode to close
      var focus = document.activeElement;
      var isAnnotationPopover = !!(focus !== null && focus !== void 0 && focus.dataset.popoverOpen) || !!(focus !== null && focus !== void 0 && focus.closest('[data-popover-open]'));
      if (!isAnnotationPopover) {
        event.preventDefault();
        event.stopPropagation();
        setIsFullScreen(false);
        returnFocus();
      }
    }
  }, []);
  var _useEuiI18n = useEuiI18n(['euiCodeBlockFullScreen.fullscreenCollapse', 'euiCodeBlockFullScreen.fullscreenExpand'], ['Collapse', 'Expand']),
    _useEuiI18n2 = _slicedToArray(_useEuiI18n, 2),
    fullscreenCollapse = _useEuiI18n2[0],
    fullscreenExpand = _useEuiI18n2[1];
  var fullScreenButton = useMemo(function () {
    var button = ___EmotionJSX(EuiButtonIcon, {
      buttonRef: toggleButtonRef,
      className: "euiCodeBlock__fullScreenButton",
      onClick: toggleFullScreen,
      iconType: isFullScreen ? 'fullScreenExit' : 'fullScreen',
      color: "text",
      "aria-label": isFullScreen ? fullscreenCollapse : fullscreenExpand
    });
    return showFullScreenButton ? isFullScreen ?
    // use delay to prevent label being updated in non-fullscreen state before fullscreen is opened
    // otherwise this causes screen readers to read the collapse label before anything else (as the button was focused when opening)
    ___EmotionJSX(EuiDelayRender, {
      delay: 10
    }, button) : button : null;
  }, [showFullScreenButton, toggleFullScreen, isFullScreen, fullscreenCollapse, fullscreenExpand]);
  return {
    fullScreenButton: fullScreenButton,
    isFullScreen: isFullScreen,
    onKeyDown: onKeyDown
  };
};

/**
 * Portalled full screen wrapper
 */
export var EuiCodeBlockFullScreenWrapper = ({
  children,
  onClose
}) => {
  const styles = useEuiMemoizedStyles(euiCodeBlockStyles);
  const cssStyles = [styles.euiCodeBlock, styles.l,
  // Force fullscreen to use large font
  styles.isFullScreen];
  const ariaLabel = useEuiI18n('euiCodeBlockFullScreen.ariaLabel', 'Expanded code block');
  const dialogProps = {
    role: 'dialog',
    'aria-modal': true,
    'aria-label': ariaLabel,
    onKeyDown: onClose
  };
  return <EuiOverlayMask>
      <EuiFocusTrap scrollLock preventScrollOnFocus clickOutsideDisables={true}>
        <div className="euiCodeBlockFullScreen" css={cssStyles} {...dialogProps}>
          {children}
        </div>
      </EuiFocusTrap>
    </EuiOverlayMask>;
};
EuiCodeBlockFullScreenWrapper.propTypes = {
  onClose: PropTypes.func.isRequired
};
try {
  useFullScreen.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/code/code_block_full_screen.tsx',
    description: 'Hook that returns fullscreen-related state/logic/utils',
    displayName: 'useFullScreen',
    methods: [],
    props: {
      overflowHeight: {
        defaultValue: null,
        description: '',
        name: 'overflowHeight',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/code_block_full_screen.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  EuiCodeBlockFullScreenWrapper.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/code/code_block_full_screen.tsx',
    description: 'Portalled full screen wrapper',
    displayName: 'EuiCodeBlockFullScreenWrapper',
    methods: [],
    props: {
      onClose: {
        defaultValue: null,
        description: '',
        name: 'onClose',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/code_block_full_screen.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(event: React.KeyboardEvent<HTMLElement>) => void'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}