/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { htmlIdGenerator, withEuiTheme } from '../../services';
import { EuiLoadingSpinner } from '../loading';
import { EuiAccordionTrigger } from './accordion_trigger';
import { EuiAccordionChildren } from './accordion_children';
import { euiAccordionStyles } from './accordion.styles';
export var PADDING_SIZES = ['none', 'xs', 's', 'm', 'l', 'xl'];
export class EuiAccordionClass extends Component {
  static defaultProps = {
    initialIsOpen: false,
    borders: 'none',
    paddingSize: 'none',
    arrowDisplay: 'left',
    isLoading: false,
    isDisabled: false,
    isLoadingMessage: false,
    element: 'div',
    buttonElement: 'button',
    role: 'group'
  };
  state = {
    isOpen: this.props.forceState ? this.props.forceState === 'open' : this.props.initialIsOpen
  };
  get isOpen() {
    return this.props.forceState ? this.props.forceState === 'open' : this.state.isOpen;
  }
  onToggle = () => {
    const {
      forceState
    } = this.props;
    if (forceState) {
      const nextState = !this.isOpen;
      this.props.onToggle?.(nextState);
    } else {
      this.setState(prevState => ({
        isOpen: !prevState.isOpen
      }), () => {
        this.props.onToggle?.(this.state.isOpen);
      });
    }
  };
  generatedId = htmlIdGenerator()();
  render() {
    const {
      children,
      className,
      id,
      role,
      element: Element = 'div',
      buttonElement,
      buttonProps,
      buttonClassName,
      buttonContentClassName,
      buttonContent,
      arrowDisplay,
      arrowProps,
      extraAction,
      paddingSize,
      borders,
      initialIsOpen,
      forceState,
      isLoading,
      isLoadingMessage,
      isDisabled,
      theme,
      ...rest
    } = this.props;
    const classes = classNames('euiAccordion', {
      'euiAccordion-isOpen': this.isOpen
    }, className);
    const styles = euiAccordionStyles(theme);
    const cssStyles = [styles.euiAccordion, borders !== 'none' && styles.borders.borders, borders !== 'none' && styles.borders[borders]];
    const buttonId = buttonProps?.id ?? this.generatedId;
    return <Element className={classes} css={cssStyles} {...rest}>
        <EuiAccordionTrigger ariaControlsId={id} buttonId={buttonId}
      // Force button element to be a legend if the element is a fieldset
      buttonElement={Element === 'fieldset' ? 'legend' : buttonElement} buttonClassName={buttonClassName} buttonContent={buttonContent} buttonContentClassName={buttonContentClassName} buttonProps={buttonProps} arrowProps={arrowProps} arrowDisplay={arrowDisplay} isDisabled={isDisabled} isOpen={this.isOpen} onToggle={this.onToggle} extraAction={isLoading ? <EuiLoadingSpinner /> : extraAction} />

        <EuiAccordionChildren role={role} id={id} aria-labelledby={buttonId} paddingSize={paddingSize} isLoading={isLoading} isLoadingMessage={isLoadingMessage} isOpen={this.isOpen}>
          {children}
        </EuiAccordionChildren>
      </Element>;
  }
}
EuiAccordionClass.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  id: PropTypes.string.isRequired,
  /**
       * Applied to the entire .euiAccordion wrapper.
       * When using `fieldset`, it will enforce `buttonElement = legend` as well.
       */
  element: PropTypes.oneOf(["div", "fieldset"]),
  /**
       * Defaults to the [group role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/group_role).
       *
       * If your accordion contains significant enough content to be a document
       * [landmark role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/region_role#accessibility_concerns), consider using the `region` role instead.
       * @default group
       */
  role: PropTypes.any,
  /**
       * Class that will apply to the trigger for the accordion.
       */
  buttonClassName: PropTypes.string,
  /**
       * Apply more props to the triggering button.
       *
       * Includes optional `paddingSize` prop which allows sizes of `s`, `m`, or `l`.
       * Note: Padding will not be present on the side closest to the accordion arrow.
       */
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any,
    paddingSize: PropTypes.oneOf(["s", "m", "l"])
  }),
  /**
       * Class that will apply to the trigger content for the accordion.
       */
  buttonContentClassName: PropTypes.string,
  /**
       * The content of the clickable trigger
       */
  buttonContent: PropTypes.node,
  /**
       * Applied to the main button receiving the `onToggle` event.
       * Anything other than the default `button` does not support removing the arrow display (for accessibility of focus).
       */
  buttonElement: PropTypes.oneOf(["div", "legend", "button"]),
  /**
       * Extra props to pass to the EuiButtonIcon containing the arrow.
       */
  arrowProps: PropTypes.any,
  /**
       * Will appear right aligned against the button. Useful for separate actions like deletions.
       */
  extraAction: PropTypes.node,
  /**
       * The accordion will start in the open state.
       */
  initialIsOpen: PropTypes.bool,
  /**
       * Optional callback method called on open and close with a single `isOpen` parameter
       */
  onToggle: PropTypes.func,
  /**
       * The padding around the exposed accordion content.
       */
  paddingSize: PropTypes.any,
  /**
       * Placement of the arrow indicator, or 'none' to hide it.
       */
  arrowDisplay: PropTypes.oneOf(["left", "right", "none"]),
  /**
       * Optional border styling. Defaults to 'none'.
       */
  borders: PropTypes.oneOf(["horizontal", "all", "none"]),
  /**
       * Control the opening of accordion via prop
       */
  forceState: PropTypes.oneOf(["closed", "open"]),
  /**
       * Change `extraAction` and children into a loading spinner
       */
  isLoading: PropTypes.bool,
  /**
       * Choose whether the loading message replaces the content. Customize the message by passing a node
       */
  isLoadingMessage: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.node.isRequired]),
  /**
       * Disable the open/close interaction and visually subdues the trigger
       */
  isDisabled: PropTypes.bool
};
export const EuiAccordion = withEuiTheme(EuiAccordionClass);
try {
  EuiAccordionClass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/accordion/accordion.tsx',
    description: '',
    displayName: 'EuiAccordionClass',
    methods: [],
    props: {
      theme: {
        defaultValue: null,
        description: '',
        name: 'theme',
        parent: {
          fileName: 'eui/src/services/theme/hooks.tsx',
          name: 'WithEuiThemeProps'
        },
        declarations: [{
          fileName: 'eui/src/services/theme/hooks.tsx',
          name: 'WithEuiThemeProps'
        }],
        required: true,
        type: {
          name: 'UseEuiTheme<{}>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      element: {
        defaultValue: {
          value: "'div' as const"
        },
        description: 'Applied to the entire .euiAccordion wrapper.\n' + 'When using `fieldset`, it will enforce `buttonElement = legend` as well.',
        name: 'element',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"div" | "fieldset"',
          value: [{
            value: '"div"'
          }, {
            value: '"fieldset"'
          }]
        }
      },
      role: {
        defaultValue: {
          value: "'group' as const"
        },
        description: 'Defaults to the [group role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/group_role).\n' + '\n' + 'If your accordion contains significant enough content to be a document\n' + '[landmark role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/region_role#accessibility_concerns), consider using the `region` role instead.',
        name: 'role',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'AriaRole'
        }
      },
      buttonClassName: {
        defaultValue: null,
        description: 'Class that will apply to the trigger for the accordion.',
        name: 'buttonClassName',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      buttonProps: {
        defaultValue: null,
        description: 'Apply more props to the triggering button.\n' + '\n' + 'Includes optional `paddingSize` prop which allows sizes of `s`, `m`, or `l`.\n' + 'Note: Padding will not be present on the side closest to the accordion arrow.',
        name: 'buttonProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLElement> & { paddingSize?: "s" | "m" | "l"; }'
        }
      },
      buttonContentClassName: {
        defaultValue: null,
        description: 'Class that will apply to the trigger content for the accordion.',
        name: 'buttonContentClassName',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      buttonContent: {
        defaultValue: null,
        description: 'The content of the clickable trigger',
        name: 'buttonContent',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      buttonElement: {
        defaultValue: {
          value: "'button' as const"
        },
        description: 'Applied to the main button receiving the `onToggle` event.\n' + 'Anything other than the default `button` does not support removing the arrow display (for accessibility of focus).',
        name: 'buttonElement',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "div" | "legend"',
          value: [{
            value: '"button"'
          }, {
            value: '"div"'
          }, {
            value: '"legend"'
          }]
        }
      },
      arrowProps: {
        defaultValue: null,
        description: 'Extra props to pass to the EuiButtonIcon containing the arrow.',
        name: 'arrowProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiButtonIconProps, "aria-labelledby" | "onClick" | "iconType">>'
        }
      },
      extraAction: {
        defaultValue: null,
        description: 'Will appear right aligned against the button. Useful for separate actions like deletions.',
        name: 'extraAction',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      initialIsOpen: {
        defaultValue: {
          value: 'false'
        },
        description: 'The accordion will start in the open state.',
        name: 'initialIsOpen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onToggle: {
        defaultValue: null,
        description: 'Optional callback method called on open and close with a single `isOpen` parameter',
        name: 'onToggle',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(isOpen: boolean) => void'
        }
      },
      paddingSize: {
        defaultValue: {
          value: "'none' as const"
        },
        description: 'The padding around the exposed accordion content.',
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "s" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"none"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      arrowDisplay: {
        defaultValue: {
          value: "'left' as const"
        },
        description: "Placement of the arrow indicator, or 'none' to hide it.",
        name: 'arrowDisplay',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "left" | "right"',
          value: [{
            value: '"none"'
          }, {
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      borders: {
        defaultValue: {
          value: "'none' as const"
        },
        description: "Optional border styling. Defaults to 'none'.",
        name: 'borders',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "horizontal" | "all"',
          value: [{
            value: '"none"'
          }, {
            value: '"horizontal"'
          }, {
            value: '"all"'
          }]
        }
      },
      forceState: {
        defaultValue: null,
        description: 'Control the opening of accordion via prop',
        name: 'forceState',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"closed" | "open"',
          value: [{
            value: '"closed"'
          }, {
            value: '"open"'
          }]
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: 'Change `extraAction` and children into a loading spinner',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoadingMessage: {
        defaultValue: {
          value: 'false'
        },
        description: 'Choose whether the loading message replaces the content. Customize the message by passing a node',
        name: 'isLoadingMessage',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      isDisabled: {
        defaultValue: {
          value: 'false'
        },
        description: 'Disable the open/close interaction and visually subdues the trigger',
        name: 'isDisabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['WithEuiThemeProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'Attributes']
  };
} catch (e) {}
try {
  EuiAccordion.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/accordion/accordion.tsx',
    description: '',
    displayName: 'EuiAccordion',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      role: {
        defaultValue: {
          value: 'group'
        },
        description: 'Defaults to the [group role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/group_role).\n' + '\n' + 'If your accordion contains significant enough content to be a document\n' + '[landmark role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/region_role#accessibility_concerns), consider using the `region` role instead.',
        name: 'role',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'AriaRole'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      element: {
        defaultValue: null,
        description: 'Applied to the entire .euiAccordion wrapper.\n' + 'When using `fieldset`, it will enforce `buttonElement = legend` as well.',
        name: 'element',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"div" | "fieldset"',
          value: [{
            value: '"div"'
          }, {
            value: '"fieldset"'
          }]
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Change `extraAction` and children into a loading spinner',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: 'Disable the open/close interaction and visually subdues the trigger',
        name: 'isDisabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      buttonClassName: {
        defaultValue: null,
        description: 'Class that will apply to the trigger for the accordion.',
        name: 'buttonClassName',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      buttonProps: {
        defaultValue: null,
        description: 'Apply more props to the triggering button.\n' + '\n' + 'Includes optional `paddingSize` prop which allows sizes of `s`, `m`, or `l`.\n' + 'Note: Padding will not be present on the side closest to the accordion arrow.',
        name: 'buttonProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLElement> & { paddingSize?: "s" | "m" | "l"; }'
        }
      },
      buttonContentClassName: {
        defaultValue: null,
        description: 'Class that will apply to the trigger content for the accordion.',
        name: 'buttonContentClassName',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      buttonContent: {
        defaultValue: null,
        description: 'The content of the clickable trigger',
        name: 'buttonContent',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      buttonElement: {
        defaultValue: null,
        description: 'Applied to the main button receiving the `onToggle` event.\n' + 'Anything other than the default `button` does not support removing the arrow display (for accessibility of focus).',
        name: 'buttonElement',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "div" | "legend"',
          value: [{
            value: '"button"'
          }, {
            value: '"div"'
          }, {
            value: '"legend"'
          }]
        }
      },
      arrowProps: {
        defaultValue: null,
        description: 'Extra props to pass to the EuiButtonIcon containing the arrow.',
        name: 'arrowProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiButtonIconProps, "aria-labelledby" | "onClick" | "iconType">>'
        }
      },
      extraAction: {
        defaultValue: null,
        description: 'Will appear right aligned against the button. Useful for separate actions like deletions.',
        name: 'extraAction',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      initialIsOpen: {
        defaultValue: null,
        description: 'The accordion will start in the open state.',
        name: 'initialIsOpen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onToggle: {
        defaultValue: null,
        description: 'Optional callback method called on open and close with a single `isOpen` parameter',
        name: 'onToggle',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(isOpen: boolean) => void'
        }
      },
      paddingSize: {
        defaultValue: null,
        description: 'The padding around the exposed accordion content.',
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "s" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"none"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      arrowDisplay: {
        defaultValue: null,
        description: "Placement of the arrow indicator, or 'none' to hide it.",
        name: 'arrowDisplay',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "left" | "right"',
          value: [{
            value: '"none"'
          }, {
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      borders: {
        defaultValue: null,
        description: "Optional border styling. Defaults to 'none'.",
        name: 'borders',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "horizontal" | "all"',
          value: [{
            value: '"none"'
          }, {
            value: '"horizontal"'
          }, {
            value: '"all"'
          }]
        }
      },
      forceState: {
        defaultValue: null,
        description: 'Control the opening of accordion via prop',
        name: 'forceState',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"closed" | "open"',
          value: [{
            value: '"closed"'
          }, {
            value: '"open"'
          }]
        }
      },
      isLoadingMessage: {
        defaultValue: null,
        description: 'Choose whether the loading message replaces the content. Customize the message by passing a node',
        name: 'isLoadingMessage',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<unknown>'
        }
      }
    },
    extendedInterfaces: ['WithEuiThemeProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'Attributes']
  };
} catch (e) {}