import React from 'react';
export var TexturedStylesProps = () => <div />;
try {
  TexturedStylesProps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/elastic_charts/texture_props.tsx',
    description: '',
    displayName: 'TexturedStylesProps',
    methods: [],
    props: {
      path: {
        defaultValue: null,
        description: 'path for polygon texture',
        name: 'path',
        parent: {
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedPathStyles'
        },
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedPathStyles'
        }],
        required: true,
        type: {
          name: 'string | Path2D'
        }
      },
      fill: {
        defaultValue: null,
        description: 'polygon fill color for texture',
        name: 'fill',
        parent: {
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        },
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      stroke: {
        defaultValue: null,
        description: 'polygon stroke color for texture',
        name: 'stroke',
        parent: {
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        },
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      strokeWidth: {
        defaultValue: null,
        description: 'polygon stroke width for texture',
        name: 'strokeWidth',
        parent: {
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        },
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      opacity: {
        defaultValue: null,
        description: 'polygon opacity for texture',
        name: 'opacity',
        parent: {
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        },
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      dash: {
        defaultValue: null,
        description: 'polygon opacity for texture',
        name: 'dash',
        parent: {
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        },
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        }],
        required: false,
        type: {
          name: 'number[]'
        }
      },
      size: {
        defaultValue: null,
        description: 'polygon opacity for texture',
        name: 'size',
        parent: {
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        },
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      rotation: {
        defaultValue: null,
        description: 'The angle of rotation for entire texture\nin degrees',
        name: 'rotation',
        parent: {
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        },
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      shapeRotation: {
        defaultValue: null,
        description: 'The angle of rotation for polygons\nin degrees',
        name: 'shapeRotation',
        parent: {
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        },
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      spacing: {
        defaultValue: null,
        description: 'texture spacing between polygons',
        name: 'spacing',
        parent: {
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        },
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        }],
        required: false,
        type: {
          name: 'number | Partial<Point>'
        }
      },
      offset: {
        defaultValue: null,
        description: 'overall origin offset of pattern',
        name: 'offset',
        parent: {
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        },
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedStylesBase'
        }],
        required: false,
        type: {
          name: 'Partial<Point> & { global?: boolean; }'
        }
      },
      shape: {
        defaultValue: null,
        description: 'typed of texture designs currently supported',
        name: 'shape',
        parent: {
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedShapeStyles'
        },
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/utils/themes/theme.d.ts',
          name: 'TexturedShapeStyles'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: 'TextureShape',
          value: [{
            value: '"plus"'
          }, {
            value: '"x"'
          }, {
            value: '"circle"'
          }, {
            value: '"line"'
          }, {
            value: '"square"'
          }, {
            value: '"triangle"'
          }, {
            value: '"diamond"'
          }]
        }
      }
    },
    extendedInterfaces: ['TexturedPathStyles', 'TexturedStylesBase', 'TexturedShapeStyles']
  };
} catch (e) {}